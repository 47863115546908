.chart-title {
	min-width: 100px;
}

.fc__tooltip {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	border: none !important;
	opacity: 1 !important;
	background-color: rgba(246, 246, 246, .95)!important;;
	padding: 25px 30px !important;
}

.fc__tooltip>div {
	opacity: 0;
}

.fc__tooltip_label {
	margin-bottom: 10px;
	font-size: 1rem;
	color: #4A4A4A;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.fc__tooltip_list-wrapper {
	display: table;
	border-spacing: 0px 7px;
	padding: 0;
	list-style-type: none; 
}

.fc__tooltip_item {
	display: table-row;
	position: relative;
	font-size: .9rem;
	vertical-align: center;
	line-height: 1.3rem;
}

.fc__tooltip_boll {
	display: block;
	position: absolute;
	top: 0;
	width: 18px;
	height: 18px;
	border-radius: 50%; 
}

.fc__tooltip_bollwrapper {
	position: relative;
	display: table-cell;
	padding-right: 29px;
}

.fc__tooltip_text {
	display: table-cell;
	padding-right: 7px;
}

.fc__tooltip_num {
	display: table-cell;
	padding-right: 7px;
	text-align: right;
}

.multiple-input-arr .ui-select-match-item{
    background-color: #dbeafa;
    color: #4b8fdf;
    border-color: #dbeafa;
    font-size: 14px;
    padding-left: 5px;
    overflow: hidden;
}

.dashboard-percent {
	font-size: 24px;
}

.dashboard-less {
	align-self: center;
	font-size: 25px;
}