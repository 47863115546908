.react-modal-policy {
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 15px;
    padding-bottom: 15px;

    &_error {
        display: flex;
        align-items: center;
        border-top: 1px solid #e5e5e5;
    }

    &_warning {
        color: #8a6d3b;
        background-color: #fcf8e3;
        padding: 15px;
        margin-bottom: 20px;
        border: 1px solid #faebcc;
        border-radius: 4px;
    }

    &_length {
        display: inline-block;
        width: 60px;
        height: 34px;
        padding: 6px 12px;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0px -1px 1px rgb(243 242 242);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        line-height: 1.24rem;
        color: #555;
        appearance: none;
        outline: none;
        font-size: 14px;

        &:active {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
        &:focus {
            box-shadow: 0 0 6px rgb(167 206 238);
        }

        &:disabled {
            cursor: not-allowed;
            background-color: #eee;
        }
    }

    &_block {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
}

.modal-policy_checkox-input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
}

.modal-policy_checkox-input:checked {
    & + .modal-policy_checkbox-label::before {
        content: "";
        background-image: url("../../../images/lg_selected.svg");
    }
}

.modal-policy_checkbox-label {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.modal-policy_checkbox-label::before {
    content: "";
    background-image: url("../../../images/lg_empty.svg");
    width: 14px;
    height: 14px;
    margin-right: 7px;
}

.str1,
.str2,
.str3,
.warning-none {
    display: none;
}

.text-error {
    color: #a94442;
}

.input-error {
    border-color: #a94442;
}
