.table {
    display: table;
    width: 100%;
}

.header-row > th {
    white-space: nowrap;
    line-height: 37px;
    vertical-align: middle;
    width: auto;
    text-align: left;
    font-weight: 400;
    padding-right: 1.5rem;
}

.header-row > th.ico-cell {
    padding-right: 10px;
}

.body-row {
    line-height: 34px;
}

.body-row:hover {
    background-color: #fcf7e3;
}

.body-row:hover .checkbox,
.body-row:hover .ico {
    opacity: 1;
}

.th-checkbox {
    width: 50px !important;
    min-width: 50px;
}

.th-a, .th-a:hover {
    color: #000000 !important;
    font-weight: 700 !important;
    text-decoration: none !important;
    box-shadow: none !important;
}

.icons-wrapper {
    margin-right: 7px;
}

.bottom-container {
    padding: 10px 0 10px 60px;
    line-height: 18px;
}

.bottom-report-container {
    padding: 10px 0 10px 10px;
    line-height: 18px;
}

.bottom-vuln-container {
    padding: 10px 0 10px 30px;
    line-height: 18px;
}

.targets-table {
    display: block;
    margin-bottom: 10px;
    font-size: 13.5px;
    /* не делаем жестких метрик внешнего контейнера в самом компоненте */
    /*width: 1070px;*/
}

.loader {
    margin-top: 20px;
}

.action-edit {
    width: 15px;
    height: 15px;
    opacity: 0;
}

.nowrap {
    white-space: nowrap;
}

.name-cell {
    line-height: 30px;
}

.situation {
    top: 13px;
}

.education-situation {
    line-height: 30px;
}

.education-additional-info {
    padding-left: 25px;
}

.education-score {
    padding-right: 23px;
    text-align: right;
}

.long-content-cell {
    line-height: 20px;
}

.situation-cell {
    width: 50px;
}

.report-row {
    line-height: 30px;
}

.vulns {
    margin-left: -3px;
}

.vulns .th-checkbox {
    width: 20px !important;
    min-width: 20px;
}

.checkbox {
    position: relative;
    top: 2px;
}

.name-text {
    display: flex;
}