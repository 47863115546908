.link {
    padding: 0 4px;
}

.link:first-child {
    padding-left: 15px;
}

.links-wrapper {
    margin-bottom: 15px;
}

.input-group {
    display: flex;
    margin-bottom: 15px;
}

.delay-value {
    margin-right: 25px;
}