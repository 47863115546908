.wrapper {
    display: flex;
    align-items: baseline;
    padding: 20px 2em;
    border-top: 1px solid #e5e5e5;
}

.item {
    margin-right: 1em;
}
