.link {
	margin-left: 12px;
}

.title {
	font-size: 1.7rem;
    line-height: 34px;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
    display: inline-block;
}

.wrapper {
	float: none;
	width: auto;
	margin-left: auto;
}

.mb {
	margin-bottom: 1.68rem;
}