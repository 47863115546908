.wrapper {
    padding: .2rem 0;
}

.title {
    display: inline-block;
    font-size: 21px;
    line-height: 2rem;
    margin-bottom: 8px;
}

.link-wrapper {
    margin-bottom: 1.2rem;
}

.sub-departments {
    font-weight: 600;
}

.no-targets {
    color: #777;
}

.progress {
    height: 35px;
    min-width: 120px;
    margin-bottom: 15px;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
}

.progress-text {
    font-size: 16px;
    line-height: 35px;
}

.item {
    min-width: 30px;
}
