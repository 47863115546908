.wrapper {
    display: flex;
}

.checkbox {
	margin-right: 5px;
}

.stuck {
    margin-bottom: 0;
}

.label_and {
    text-align: right;
}
