.wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.icon-password {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    width: 25px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
}

.password {
    font-weight: normal;
}

.icon-show {
    background-image: url("../../../images/icon_show.svg");
}

.icon-hidden {
    background-image: url("../../../images/icon_hide.svg");
}

.password-label {
    margin-right: 15px;
    width: 67px;
    text-align: right;
}
