.wrapper {
	display: flex;
	height: 20px;
	width: 300px;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
}

.licence-count {
	height: 20px;
	background-color: rgba(155, 155, 155, .45);
}

.real-count {
	height: 20px;
	width: 50%;
	/*min-width: 10px;*/
	background-color: #5cb85c;
}

.not-licence {
	height: 20px;
	opacity: .55;
	background-color: #DA5855;
}
