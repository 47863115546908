.common-link-error {
    padding-left: 10px;
}

.toolbar {
    position: absolute;
    right: 25px;
}

.links {
    margin-left: 30px;
}

.table-container {
    background: #ffff;
    width: fit-content;
    min-width: 100%;
}
