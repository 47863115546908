.img-wrapper {
    display: flex;
}

.img {
    width: 260px;
}

.image-atr {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}

.size {
    flex-grow: 1;
    white-space: nowrap;
}

.text-green {
    color: #417505;
}

.link {
    cursor: pointer;
    padding-top: 0px !important;
}

.cert-post {
    margin-left: 10px;
}

.spin {
    width: 14px;
}

.label {
    padding-right: 3px;
    text-align: right;
}

.checkbox {
    margin-right: 0;
}

.progress {
    line-height: 20px;
}
