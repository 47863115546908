.react-dropdown {
    position: relative;

    &_options {
        display: none;
        position: absolute;
        top: 41px;
        left: 15px;
        flex-direction: column;
        max-height: 200px;
        overflow-y: auto;
        z-index: 10;
        padding: 5px 0;
        border: 1px solid rgba(0, 0, 0, .15);
        border-radius: 4px;
        box-shadow: ~'0 6px 12px rgba(0, 0, 0, .18)';

        background-color: #fff;
    }

    &_open {
        display: flex;
    }

    &_item {
        display: flex;
        align-items: center;
        padding: 3px 20px;

        cursor: pointer;
        white-space: nowrap;

        &:hover {
            background-color: @hover-item;
            color: #fff;
        }
    }

    &_span {
        display: flex;
    }

    &_ico {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        margin-left: -10px;
    }
}
