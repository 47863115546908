.wrapper {
    margin-bottom: 1.24rem;
}

.btn-dngr {
    position: relative;
    padding-left: 28px !important;
}

.pr-12 {
    padding-right: 12px;
}

.ico {
    position: absolute;
    top: 6px;
    left: 9px;
    width: 16px;

    background-image: url('../../../images/most-dngrs.svg');
}

.active {
    color: #333;
    background-color: #d4d4d4 !important;
    border-color: #8c8c8c !important;
}
