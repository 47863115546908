.wifiAttack-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 40px;
    padding-right: 190px;

    &_link {
        &:hover {
            box-shadow: none;
        }
    }

    &_link-none {
        display: none;
    }

    &_label {
        width: 160px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #333;
    }

    &_drop-select {
        cursor: pointer;
        margin-right: 12px;

    }

    &_final-icon {
        cursor: pointer;
        path {
            fill: #9B9B9B;
            transition: fill .2s ease-in-out;
        }

        &:hover path {
            fill: #337ab7;
        }
    }

    &_input {
        width: 466px;
        height: 40px;
        border: 1px solid #cbcccb;
        border-radius: 4px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 14px;
        box-shadow: 0px -1px 1px rgb(243 242 242);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        line-height: 1.24rem;
        color: #555;
        appearance: none;
        outline: none;
        font-size: 14px;

        &:active {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
        &:focus {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
    }

    &_block-top {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        width: 100%;
    }

    &_block {
        display: flex;
        align-items: center;
        margin-bottom: 42px;
        width: 100%;
    }

    &_process {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 20px;
    }
}

.modal-loader {
    width: 40px;
    height: 40px;
    display: inline;
    margin-left: 48%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.text-error {
    color: #a94442;
}

.input-error {
    border-color: #a94442;
}

.wifiAttack-modal_select__control {
    width: 466px;
    height: 40px;
    border: 1px solid #cbcccb;
    border-radius: 4px;
    padding-left: 12px;
    box-shadow: 0px -1px 1px rgb(243 242 242);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    line-height: 1.24rem;
    color: #555;
    appearance: none;
    outline: none;
    font-size: 14px;
    margin-right: 8px;

    &--is-focused {
        box-shadow: 0 0 6px rgb(167 206 238);
    }

    & + .blocked {
        cursor: not-allowed;
    }
}

.wifiAttack-modal_select-error__control {
    border: 1px solid #a94442;
    width: 466px;
    height: 40px;
    border-radius: 4px;
    padding-left: 12px;
    box-shadow: 0px -1px 1px rgb(243 242 242);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    line-height: 1.24rem;
    color: #555;
    appearance: none;
    outline: none;
    font-size: 14px;
    margin-right: 8px;

    &--is-focused {
        box-shadow: 0 0 6px rgb(172, 94, 94);
    }

    & + .blocked {
        cursor: not-allowed;
    }
}

.wifiAttack-modal_select__indicator-separator,
.wifiAttack-modal_select-error__indicator-separator {
    display: none;
}

.checkbox-modal-phishingPage,
.checkbox-modal-ldap,
.checkbox-modal-token {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    width: 117px;
    height: 22px;
}

.checkbox-modal-phishingPage:checked {
    & + .wifiAttack-modal_label-phishingPage::before {
        content: "";
        background-image: url("../../../images/lg-select.svg");
        background-repeat: no-repeat;
        background-position: center;
    }
}

.wifiAttack-modal_label-phishingPage {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 160px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #333;
}

.wifiAttack-modal_label-ldap,
.wifiAttack-modal_label-token {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #333;
}

.wifiAttack-modal_label-phishingPage::before {
    content: "";
    border: 1px solid #cbcccb;
    border-radius: 4px;
    height: 20px;
    margin-right: 8px;
    padding-right: 18px;
}

.wifiAttack-modal_label-ldap::before,
.wifiAttack-modal_label-token::before {
    content: "";
    border: 1px solid #cbcccb;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.checkbox-modal-ldap:checked {
    & + .wifiAttack-modal_label-ldap::before {
        content: "";
        background-image: url("../../../images/lg-select.svg");
        background-repeat: no-repeat;
        background-position: center;
    }
}

.checkbox-modal-token:checked {
    & + .wifiAttack-modal_label-token::before {
        content: "";
        background-image: url("../../../images/lg-select.svg");
        background-repeat: no-repeat;
        background-position: center;
    }
}

