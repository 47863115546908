.wifi-attack {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;

    &_delete-popup {
        position: fixed;
        display: flex;
        align-items: center;
        padding: 16px;
        background-color: #1c1c1c;
        box-shadow: 0px 4px 12px rgba(16, 16, 16, 0.08);
        border-radius: 8px;
        bottom: 6%;
        left: calc(50vw - 220px);
        z-index: 100;
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        max-width: 500px !important;
        width: max-content !important;

        &_content {
            display: flex;
            align-items: center;
        }

        &_img {
            width: 40px;
            height: 40px;
            margin-right: 12px;
        }

        &_text {
            margin-right: 12px;
        }

        &_btn {
            padding: 6px 12px;
            background-color: rgba(255, 255, 255, 0.22);
            border-radius: 4px;
            border: none;
            outline: none;
        }
    }

    &_report-popup {
        position: fixed;
        bottom: 6%;
        left: calc(50vw - 153px);
        z-index: 100;

        &_content {
            display: flex;
            align-items: center;
            padding: 16px;
        }

        &_icon {
            width: 40px;
            height: 40px;
            margin-right: 12px;
        }

        &_load {
            position: absolute;
            width: 40px;
            height: 40px;
            margin-right: 12px;
        }

        &_text {
            font-size: 14px;
            line-height: 18px;
            color: #fff;
        }
    }

    &_general-report {
        position: fixed;
        bottom: 0;
        left: calc(-50vw + 50%);
        z-index: 1;
        width: 100vw;
        height: 80px;
        background-color: #fafafa;
        border-top: 1px solid rgba(0, 0, 0, 0.12);

        &-btn {
            background-color: #4caf50;
            border-radius: 4px;
            border: none;
            outline: none;
            padding: 10px 16px;
            font-size: 14px;
            line-height: 20px;
            color: #fff;

            &:disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }

        &-icon {
            stroke: #fff;
            width: 12px;
            height: 12px;
            fill: none;
            margin-right: 6px;
        }

        &-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 68rem;
            margin-left: auto;
            margin-right: auto;
            padding: 8px 8px 16px;
        }

        &-left {
            font-size: 16px;
            line-height: 24px;
            color: #333;
            margin-bottom: 4px;
        }

        &-calendar {
            color: #337ab7;
            margin-left: 4px;
            cursor: pointer;
        }
    }

    &_ldap-popup {
        display: flex;
        align-items: flex-start;
        position: absolute;
        top: 102%;
        right: -223px;
        width: 221px;
        height: 100px;
        padding: 8px;
        background-color: rgb(247 238 249);
        border-radius: 8px;
        font-size: 14px;
        line-height: 20px;

        &-content {
            display: flex;
            flex-direction: column;
            flex-basis: 92%;
        }

        &_svg {
            margin-top: 5px;
            width: 10px;
            height: 10px;
            fill: none;
            stroke: #333;
            cursor: pointer;

            &:hover {
                stroke: #000;
            }
        }

        &-text {
            color: #333;
            margin-bottom: 4px;
        }

        &-link {
            width: max-content;
        }
    }

    &_calendar {
        display: inline-block;
    }

    &_date-picker {
        border: none;
        background-color: transparent;
        font-size: 16px;
        line-height: 24px;
        color: #337ab7;
    }

    &_report-checkbox {
        font-size: 14px;
    }

    &_toolbar {
        position: sticky;
        top: 17%;
        z-index: 1;
        width: 100%;
        padding: 16px 0;
        left: 0;
        background-color: #fff;

        &-content {
            max-width: 68rem;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
        }
    }

    &_status-play {
        background-color: #4caf50;
        border-radius: 3px;
        padding: 4px 8px 4px;
        font-size: 12px;
        line-height: 16px;
        color: #fff;
    }

    &_status-new {
        background-color: #37397f;
        border-radius: 3px;
        padding: 4px 8px 4px;
        font-size: 12px;
        line-height: 16px;
        color: #fff;
    }

    &_status-pause {
        background-color: rgba(0, 0, 0, 0.08);
        border-radius: 3px;
        padding: 4px 8px 4px;
        font-size: 12px;
        line-height: 16px;
        color: #333;
    }

    &_icon-create {
        width: 11px;
        height: 13px;
        margin-right: 6px;
    }

    &_restore {
        span {
            color: #a94442;
            margin-right: 5px;
        }

        a {
            margin-right: 5px;
        }
    }

    &_create {
        display: flex;
        align-items: center;
        padding: 6px 12px;
        background-color: #37397f;
        border-radius: 4px;
        border: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #fff;
        transition: background-color 0.2s ease-in-out;

        &:hover {
            background-color: #1c1e56;
        }
    }

    &_pagination {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;

        &-btn {
            transition: border-bottom 0.2s ease-in-out;
            background-color: transparent;
            border: none;
            color: #337ab7;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 4px;
            padding: 0 !important;

            &:hover {
                border-bottom: 1px solid #337ab7;
            }
        }
    }

    &_loader {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;

        img {
            width: 30px;
            height: 30px;
        }
    }

    &_download {
        color: #337ab7;
        border: none;
        background: transparent;
        transition: border-bottom 0.2s ease-in-out;

        &:hover {
            border-bottom: 1px solid #337ab7;
        }
    }

    &_icon-download {
        fill: none;
        width: 32px;
        height: 32px;
        margin-right: 2px;
        border-radius: 8px;
        transition: background-color 0.2s ease-in-out;
        padding: 7px;
        transition: background-color 0.2s ease-in-out;

        & path {
            stroke: #999;
            transition: stroke 0.2s ease-in-out;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.12);
        }

        &:hover path {
            stroke: #333;
        }
    }

    &_download-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &_report {
        display: inline-flex;
        align-items: center;
        width: 19%;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #333;

        span {
            width: 65%;
        }
    }

    &_row {
        position: relative;
        display: flex;
        align-items: flex-start;
        padding-top: 6px;
        padding-left: 6px;
        padding-bottom: 6px;
        transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;

        &-top {
            display: flex;
            width: 100%;
            align-items: center;
        }
    }

    &_label {
        color: #337ab7;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        margin-right: 8px;
        transition: border-bottom 0.2s ease-in-out;

        &:hover {
            box-shadow: 0px 1px 0px 0px rgba(162, 197, 238, 1);
        }
    }

    &-item_wrapper {
        border-bottom: @border;
        cursor: default;

        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }

        &-active {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }

    &-item_wrapper.focus-wrapper {
        background-color: rgba(0, 0, 0, 0.04);
    }

    &_btn {
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        border: none;
        margin-right: 8px;
        padding: 6px 12px;
        color: rgba(0, 0, 0, 0.38);
        font-size: 14px;
        cursor: default;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

        &-phishing {
            &:hover {
                box-shadow: none;
            }
        }

        &-delete {
            width: 14px;
            height: 14px;
            stroke: #aeaeae;
            fill: none;
            margin-right: 6px;
            transition: stroke 0.2s ease-in-out;
        }

        &-copy {
            width: 16px;
            height: 16px;
            stroke: #aeaeae;
            fill: none;
            margin-right: 5px;
            transition: stroke 0.2s ease-in-out;
        }

        &-play {
            width: 12px;
            height: 14px;
            stroke: #aeaeae;
            fill: none;
            margin-right: 7px;
            transition: stroke 0.2s ease-in-out;
        }

        &-pause {
            width: 16px;
            height: 16px;
            stroke: #aeaeae;
            fill: none;
            margin-right: 5px;
            transition: stroke 0.2s ease-in-out;
        }

        &-report {
            width: 14px;
            height: 14px;
            stroke: #aeaeae;
            fill: none;
            margin-right: 6px;
            transition: stroke 0.2s ease-in-out;
        }

        &-check {
            width: 15px;
            height: 14px;
            stroke: #aeaeae;
            fill: none;
            margin-right: 5px;
            transition: stroke 0.2s ease-in-out;
        }
    }

    &_active-btn {
        color: #333;
        cursor: pointer;

        &:hover,
        &:active {
            background-color: rgba(0, 0, 0, 0.12);
        }
    }

    &_active-icon {
        stroke: #333;
    }

    &_btns {
        display: flex;
        align-items: center;
    }

    &_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border: none;
        border-radius: 6px;
        background-color: transparent;
        transition: background-color 0.2s ease-in-out, stroke 0.2s ease-in-out;

        &-arrow-open {
            transform: rotate(180deg);
        }

        &-arrow {
            width: 10px;
            height: 6px;
            stroke: #999999;
            fill: none;
            transition: stroke 0.2s ease-in-out;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.12);
        }

        &:hover &-arrow {
            stroke: #333;
        }
    }

    &_table {
        display: table;
        width: 100%;
        border-collapse: collapse;
        white-space: nowrap;
        margin-left: 27px;
    }

    &-statistic {
        &_title {
            padding: 8px;
            font-weight: 400;
            font-size: 16px;
            color: #337ab7;
        }
    }
}

.wifi-target {
    &_list {
        margin-bottom: 10px;
    }

    &_block {
        display: flex;
        justify-content: center;
    }

    &_loader {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;

        img {
            width: 20px;
            height: 20px;
        }
    }

    &_empty {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #333;
    }

    &_item {
        display: grid;
        grid-template-columns: 167px 102px 256px;
        grid-column-gap: 24px;
        max-width: 66%;
        grid-auto-rows: min-content;
        padding-left: 40px;
    }

    &_label {
        padding: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #333;
    }

    &_time {
        display: flex;
        align-items: center;

        &-calendar {
            display: flex;
            margin-right: 15px;
            align-items: center;

            svg {
                width: 20px;
                height: 20px;
                margin-right: 4px;
            }
        }

        &-clock {
            display: flex;
            align-items: center;

            svg {
                width: 20px;
                height: 20px;
                margin-right: 4px;
            }
        }
    }
}

.wifi-attack-checkbox {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    width: 16px;
    height: 16px;
}

.wifi-attack-checkbox:checked {
    &+.wifi-attack-checkbox_label::before {
        content: "";
        background-image: url("../../../images/lg-select.svg");
        background-repeat: no-repeat;
        background-position: center;
    }
}

.wifi-attack-checkbox_label::before {
    display: inline-block;
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 3px;
    margin-right: 12px;
    border: 1px solid rgba(0, 0, 0, 0.23);
}

.wifi-attack-checkbox_label {
    height: 16px;
}