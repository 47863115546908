.modal-role {
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid #ccc;

    &_block {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 3.72rem;
        &.bottom-group {
            align-items: start;
        }
        &.role-mb {
            margin-bottom: 1.24rem;
        }
    }

    &_label {
        display: flex;
        margin-right: 26px;
        flex-basis: 24%;
        justify-content: flex-end;
    }

    &_input {
        flex-basis: 55%;
        padding: 6px 12px;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0px -1px 1px rgb(243 242 242);
        transition: box-shadow 0.15s ease-in-out;
        font-size: 0.92rem;
        line-height: 1.24rem;
        color: #555;
        appearance: none;
        outline: none;
        height: 40px;

        &:active {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
        &:focus {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
    }

    &_btns {
        &-item {
            color: #333;
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 0;
            padding: 6px 12px;
            font-size: 14px;
            font-weight: normal;
            border-left-color: transparent;
            &:hover {
                background-color: #e6e6e6;
                border-color: #adadad;
            }

            &.left {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                border-left-color: #ccc;
            }

            &.right {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }

    &_block-checkbox {
        display: flex;
        flex-direction: column;
    }

    &_checkbox-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }

    &_checkbox-subgroup {
        margin-left: 20px;
    }

    &_select-block {
        width: 35%;
    }

    &_select__indicator-separator {
        display: none;
    }

    &_drop-select {
        cursor: pointer;
    }

    &_checkbox {
        margin-right: .5rem;
    }

    &_checkbox-label {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 5px;
    }
}


.btn-role-active {
    background-color: #e6e6e6;
    border-color: #adadad;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .13);
}

