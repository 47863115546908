.btn-course {
    margin-right: auto;
    padding-left: 10px;
}

.control-links {
    display: flex;
    margin-top: 1.24rem;
}

.control-link {
    margin-right: 15px;
}

.course-item>span>span {
    display: inline-block;
    max-width: 100%;
    text-overflow: ellipsis;
    text-align: left;
    padding-right: 14px;
}
