.header-page {
    position: relative;
    padding-top: 40px;
    z-index: 1;
}

.title-desription() {
    font-weight: 400;
    font-size: 13.5px;
    line-height: 1.24rem;
    color: @assets-text-color;
}

.react_header-link {
    justify-content: right;
    align-items: center;
    .row();
    .col();
    .size(3.5);

    &_user-name {
        margin: 0 16px;
        display: flex;
        align-items: center;

        &:hover {
            box-shadow: none;
        }
    }

    &_logout {
        width: 17px;
        height: 20px;
    }

    &_anchor {
        margin-left: 5px;
    }
}

.header-logo {
    position: relative;
    .row();
    .col();
    .size(8.5);
    vertical-align: center;
    flex-wrap: nowrap;

    &_image {
        position: absolute;
        left: -65px;
        top: -24px;
        cursor: pointer;
    }

    &_company-name {
        font-size: 2.2rem;
        color: @link-color;
        font-weight: 600;
        cursor: pointer;
    }

    &_separator {
        padding: 0 10px;
        .title-desription();
    }
    &_description {
        .title-desription();
    }
}
