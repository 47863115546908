.edit-btn {
    min-width: 40px;
    border-left: 0;
}

.edit-inner {
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -52%);
}

.separator-like {
    min-height: 23px!important;
}

.form-control-label {
    padding-top: 9px;
}

.form-control {
    display: flex;
    align-items: center;
    padding: 0 !important;
}

.form-control-link {
    margin-right: 15px;
}

.campaign-new-target {
    position: static;
    display: flex;
    margin: 0 15px 0 0;
    padding: 0;
    align-items: center;
}

.select-all-icon {
    margin: 0;
    padding: 0;
}

.filter-ico-round {
    display: inline-flex !important;
    margin: 0 5px -3px 10px !important;
    vertical-align: baseline !important;
    cursor: pointer;
}

.search-filter-checkbox {
    background-repeat: no-repeat;
    border: 0;
    background-position: center;
    border-radius: 0;
    padding: 10px;
    margin: 0 -5px 0 10px !important;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMHYxMmgxMlYwSDB6bTExLjA3NyAxMS4wNzdILjkyM1YuOTIzaDEwLjE1NHYxMC4xNTR6bS0uOTIzLTcuNjQ0bC0uNjUtLjY2NC00Ljg4OSA1LjEyTDIuNTEgNS41NGwtLjY2NC42MzQgMi43NyAzLjA1OCA1LjUzOC01Ljc5OHoiIGZpbGwtcnVsZT0ibm9uemVybyIgZmlsbD0iIzAwMCIvPjwvc3ZnPg==)
}

.reverse-selection-icon {
    border: 0;
    border-radius: 0;
}

.form-targets {
    width: 640px;
}

.checkbox {
    margin-right: 5px;
}

.spred {
    font-size: 21px;
    line-height: 14px;
    font-weight: 800;
    position: absolute;
    bottom: 15px;
    right: 5px;
}

.lock {
    width: 60px;
    text-align: left !important;
}

.ul-drop-menu {
    min-width: 86px !important;
}

.attach-wrapper {
    display: block;
}

.iso-wrapper {
    margin-bottom: 2.14rem;
}

.footer {
    display: flex;
    justify-content: flex-end;
}

.footer-wrapper {
    border-top: none;
}

.process {
    display: flex;
    align-items: center;
    float: none;
    min-width: auto;

}

.loading {
    display: flex!important;
    align-items: center;
    margin-right: .5rem;
}
