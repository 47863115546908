.wrapper {
    padding-top: 3.4rem;
}

.title {
    display: flex;
    align-items: baseline;
    margin-right: 23px;
    font-size: 21px;
    font-size: 1.4rem;
    line-height: 37px;
    line-height: 2.48rem;
}

.header {
    align-items: baseline;
    margin-bottom: 1rem;
}

.header-link {
    align-self: baseline;
    font-size: 0.93rem;
}

.left {
    width: 55%;
    padding-top: 6px;
}

.label {
    font-size: 0.92rem;
    width: 31% !important;
    margin-right: 18px;
    text-align: right;
}

.prefix {
    margin-top: 7px;
    margin-bottom: 22px;
    line-height: 1.35rem;
}

.prefix-input {
    width: 45% !important;
}

.right {
    width: 430px;
}

.logo {
    width: 70px;
    align-self: baseline;
    margin-top: 7px;
}

.sub-title {
    margin-bottom: 16px;
}

.text-wrapper {
    line-height: 19px;
    padding: 6px 0 0 17px;
    letter-spacing: -0.5px;
}

.manifest-link {
    margin-left: 28px;
}

.link-save {
    align-self: center;
}

.copy {
    width: 20px;
    display: inline-block;
    height: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
