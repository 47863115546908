.cell {
        text-transform: capitalize;
}

.show-more {
    margin-right: .5rem;
}

.wrapper {
    margin-left: 15px;
    margin-right: 15px
}

.setting-item {
    flex-direction: column;
}
