.wrapper {
    display: flex;
    height: 20px;
    width: 300px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress {
    height: 20px;
    background-color: #5cb85c;
}

.message {
    margin-bottom: 0.5rem;
}

.link {
    display: flex;
    justify-content: space-between;
}
