.footer {
    display: flex;
    justify-content: space-between;
}

.item {
    min-width: 300px;
    width: 33.333%;
}

.item-right {
    text-align: right;
}
