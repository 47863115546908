.react-modal-header_content {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.24rem;
    padding-bottom: 1.24rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
}

.react-modal-header_title {
    margin: 0;
    line-height: 1.42857143;
    font-size: 1.2rem;
}

.react-modal-header_btn {
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.2;
    background-color: transparent;
    outline: none;
    border: none;
}
