.control-wrapper() {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
}

.control-field() {
    height: 40px;
    padding: 6px 12px;
    border: @border;
    border-radius: 4px;
}

.control-left() {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    margin-right: 0;
}

.control-center() {
    border-radius: 0;
    border-right: none;
    margin: 0;
}

.control-right() {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 0;
}

.react-prompt {
    color: @title-color;
    font-size: 12px;
}

.react-radiobutton {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid @main-color;
    border-radius: 50%;

    &_active::before {
        display: block;
        position: absolute;
        top: 1px;
        left: 1px;
        content: ' ';
        width: 16px;
        height: 16px;
        background-color: @main-color;
        border-radius: 50%;
    }
}

.react-checkbox {
    position: relative;
    width: 20px;
    height: 20px;
    border: 1px solid @main-color;
    border-radius: 4px;
    flex-shrink: 0;

    &_active::before {
        display: block;
        content: ' ';
        position: absolute;
        left: 5px;
        top: 1px;
        width: 7px;
        height: 13px;
        border: 2px solid @link-color;
        border-top: none;
        border-left: none;
        transform: rotate(45deg);
    }
}

.react-textarea_field {
    width: 100%;
    .control-field();
    height: auto;
    color: #555;
}

.react-input {
    .control-wrapper();

    &_wrapper {
        .row();
        .size(7.8);
        display: flex;
        flex-wrap: nowrap;
    }

    &_label {
        .col();
        .size(4.6);
        text-align: left;
        flex-shrink: 0;

        &__error {
            color: @error-color;
        }
    }

    &_field {
        width: 100%;
        .control-field();
        color: #555;

        &__focused {
            border-color: @focus-color;
            box-shadow: @focus-shadow;

            &:focus-visible {
                outline: none;
            }
        }

        &__error {
            border-color: @error-color;
            &:focus {
                box-shadow: @error-shadow;
            }
        }
        &__untouched {
            background-color: @untouched-control!important;
        }

        &__left {
            .control-left();
        }

        &__center {
            .control-center();
        }

        &__right {
            .control-right();
        }

        &__sm {
            height: 34px;
        }
    }
}

.react-button {
    .control-wrapper();

    &_element {
        .col();
        .control-field();
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;

        &__left {
            .control-left();
        }

        &__center {
            .control-center();
        }

        &__right {
            .control-right();
        }

        &__error {
            color: @error-color;
            border-color: @error-color;
            background-color: @error-support;
        }

        &:hover {
            background-color: #e6e6e6;
        }

        &__active {
            background-color: #e6e6e6;
            box-shadow: @active-shadow;
            &:hover {
                background-color: #d4d4d4;
            }
        }

        &__sm {
            max-height: 20px;
            box-sizing: content-box;
        }

        &__db {
            max-height: 34px;
        }

        &__protocol {
            width: auto;
        }

        &__submit {
            background-color: @link-color;
            color: white;

            &:hover {
                background-color: lighten(@link-color, 15%);
            }
        }

        &__disabled {
            background-color: #ebebeb;
            color: #aeaeae;

            &:hover {
                background-color: #ebebeb;
                color: #aeaeae;
                cursor: default;
            }
        }

        &:disabled {
            box-shadow: none;
            cursor: not-allowed;
            opacity: .65;
        }
    }
}

.multiple {
    .control-wrapper();
    .control-field();
    min-height: 32px;
    height: auto;
    flex-wrap: wrap;

    &_input {
        height: auto !important;
        border: none;
        outline: none;
    }

    &_item {
        display: flex;
        flex-wrap: nowrap;
        padding: 1px 5px;
        margin: 0 3px 3px 0;
        border-radius: 3px;
        color: @multiple-color;
        background-color: @multiple-item;
        outline: hidden;

        &__error {
            color: @error-color;
        }
    }

    &__error {
        border: @border-error;
    }

    &_text {
        margin-right: 5px;
    }
}

.time-zone_label {
    .size(5);
    margin-right: -28px;
}

.time-zone_value {
    width: 86%;
    flex-grow: 1;
    margin-left: 28px;
}

.option {
    display: block;
    background-color: green;
    color: green;
}

.close_update {
    margin: 0 5px;
    opacity: .2;
    color: black;
    font-weight: 700;
    font-size: 21px;
    cursor: pointer;
}

.fa-icon-react {
    margin-right: 5px;
}

.react-cross {
    width: 13px;
    height: 13px;
    position: relative;

    &::after {
        display: flex;
        position: absolute;
        top: 50%;
        width: 13px;
        height: 1px;
        transform: rotate(45deg);
        content: ' ';
        border: 1px solid @main-color;
    }

    &::before {
        display: flex;
        position: absolute;
        top: 50%;
        width: 13px;
        height: 1px;
        transform: rotate(-45deg);
        content: ' ';
        border: 1px solid @main-color;
    }

    &:hover::before,
    &:hover::after {
        border-color: darken(@main-color, 10%);
    }
}

.react-cross_black {
    width: 14px;
    height: 14px;
    position: relative;

    &::after {
        display: flex;
        position: absolute;
        top: 50%;
        width: 14px;
        height: 1px;
        transform: rotate(45deg);
        content: ' ';
        border: 1px solid #000;
    }

    &::before {
        display: flex;
        position: absolute;
        top: 50%;
        width: 14px;
        height: 1px;
        transform: rotate(-45deg);
        content: ' ';
        border: 1px solid #000;
    }
}

.react-placeholder {
    color: #AEAEAE;
}

.show-yet-react {
    margin-left: 0!important;
}
