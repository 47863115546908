.react-modal-footer_content {
    display: flex;
    align-items: baseline;
    padding: 20px 2rem;

    .btn-left {
        margin-right: 1rem;
        transition: background-color .2s ease-in-out;

    }

}

.react-icon-error {
    margin-right: 2px;
}

.react-span-error {
    color: #a94442;
}
