.course-table-container {
    margin-left: -15px;
}

.courses-table {
    width: 100% !important;
}

.loader {
    margin-top: 20px;
}

.bottom-container {
    padding: 12px 0 10px 15px;
}

.th-icon {
    vertical-align: text-bottom;
    line-height: initial;
}

.th-icon > ico-round {
    display: inline-block;
    vertical-align: bottom;
}

.th-icon-sortorder, .th-icon-sortorder.reverse {
    vertical-align: bottom;
    line-height: initial;
    margin-right: -11px;
    display: inline-block;
}

.th-icon-sortorder:after, .th-icon-sortorder.reverse:after {
    line-height: initial !important;
    position: initial !important;
    top: initial !important;
    right: initial !important;
    bottom: 0 !important;
    padding: 0 !important;
}

.th-first, .td-first {
    padding-left: 15px;
}

.miss {
    background-color: #a10bbf;
}

.title {
    margin-bottom: 10px;
    font-size: 21px;
    line-height: 28px;
    display: inline-flex;
}

.progress {
    display: flex;
    height: 35px;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    min-width: fit-content;
}

.progress-text {
    font-size: 19px;
    line-height: 35px;
}

.none {
    height: 36px;
    background-color: #f5f5f5;
    color: rgb(85, 85, 85);
}

.graph-wrapper {
    padding-left: 17px;
}

.item {
    min-width: 30px;
    padding: 0 .79em;
    text-align: right;
}