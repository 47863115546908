.label {
    width: 30%;
    text-align: right;
    margin-right: 1rem;
}

.input {
    width: 350px;
    padding: 6px 12px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .08);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 0.92rem;
    line-height: 1.24rem;
    color: #555;
    appearance: none;
    outline: none;
    height: 40px;
}

.input:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .08), 0 0 8px rgba(102, 175, 233, .6);
}

.has-error {
    border-color: #a94442;
}

.has-error:focus {
    border-color: #843534;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .08), 0 0 6px #ce8483;
}

.input-wrapper {
    width: 350px;
    display: flex !important;
}

.line {
    display: flex !important;
    align-items: center;
    line-height: 1.24rem !important;
}

.input-text {
    border-radius: 0 4px 4px 0 !important;
}
