.confirm-body {
    flex-direction: column;
    justify-content: left;
    align-items: baseline;
    font-size: 14px;
    border: none;
    padding-bottom: 0;
}

.confirm-header {
    display: flex;
    justify-content: space-between;
    border: none;

    &_title {
        font-family: Open Sans;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        color: rgba(0, 0, 0, 0.87);
    }
}

.confirm-block {
    margin-bottom: 2rem;
    &:last-child {
        margin-bottom: .76rem;
    }
}

.confirm-course {
    display: block;
    background: #DBEAFA;
    border-radius: 4px;
    padding: 2px 6px;
    margin: 0 2px 4px;

    color: rgba(75, 143, 223, 1);

    &_wrapper {
        display: flex;
        margin: 0 -4px;
        flex-wrap: wrap;
    }
}

.confirm-title {
    font-weight: 600;
    margin-bottom: .5rem;
    color: rgba(0, 0, 0, 0.87);
}

.confirm-targets {
    width: 100%;
    .confirm-block();
}

.confirm-table {
    width: 100%;
    max-height: 270px;
    overflow: auto;
}

.confirm-row {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(4, 193.5px);
    padding: 6px 0;
    border-bottom: 1px solid rgba(0, 0,0,.12);
}

.confirm-row_title {
    .confirm-row();
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
}

.confirm-button {
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 5px 11px;

    &__disabled {
        color: rgba(0, 0, 0, 0.26);
        border-color: rgba(0, 0, 0, 0.26);

        &:hover {
            color: rgba(0, 0, 0, 0.26);
            border-color: rgba(0, 0, 0, 0.26);
        }
    }
}

.confirm-arrow {
    margin-right: .5rem;
    fill: black;
    opacity: .86;

    &__disabled {
        opacity: .26;
    }
}

.btn_confirm {
    background-color: #6200EA;
    border: none;
    font-size: 16px;
    font-weight: 500;
    &:hover {
        background: rgba(49, 27, 146, 1);
    }
    &__disabled {
        background: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
        &:hover {
            background: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.26);
        }
    }
}

.confirm-loader {
    padding: 12px;
    box-sizing: content-box;
}

.confirm-cell {
    word-break: break-word;
}
