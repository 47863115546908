.table-row {
    line-height: 38px
}

.table-row > td,
.table-row > th {
    padding-right: 15px;
    position: relative;
}

.table-row:hover {
    background-color: #fcf7e3;
}

.table-row:hover > td > .checkbox {
    opacity: 1 !important;
}

.checkbox {
    margin-right: 7px;
}
