.languages-selector {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    &_item {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 1px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #565554;
        margin-right: 16px;
        padding-bottom: 10px;
        cursor: pointer;

        &__active {
            border-bottom: 2px solid #37397f;
            color: #37397f;
            cursor: default;
            z-index: 1;
        }
    }
}
