.input {
    border: none;
    height: auto!important;
    max-width: 68%;
    margin-right: 2px;
}

.input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 68px;
    height: 34px;
    padding: 6px 12px 6px 11px;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0 0 0 .075);
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.control {
    display: flex;
    flex-direction: column;
    width: 14px;
}

.control-button {
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    width: 14px;
    height: 11px;
    transform: scaleX(1.5);
    background-color: #eeeeee;
    cursor: pointer;
}

.control-button:hover {
    background-color: #ccc;
}

.input-wrapper:hover .control-button {
    opacity: 1;
}
