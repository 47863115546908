.react-table {
    width: auto;
    border-collapse: collapse;
    border-spacing: 0;

    &_header-cell {
        padding: 8px;
        border-bottom: 1px solid #ddd;

        line-height: 1rem;
        font-weight: 600;
        color: @assets-color;
    }

    &_row {
        cursor: pointer;
        border-top: 1px solid #ddd;

        &:hover {
            background-color: @hover-element-color;

            > .react-table_cell:last-child {
                opacity: .8;
            }
        }
    }

    &__empty {
        padding: 8px;
    }

    &_cell {
        padding: 8px;
        min-width: 55px;
        box-sizing: border-box;
        min-height: 35px;
        line-height: 1.25rem;

        &:last-child {
            display: flex;
            justify-content: flex-end;
            opacity: 0;
        }

        &__disabled {
            opacity: 0.5;
        }
    }
}

