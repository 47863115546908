.targets-delete-modal {
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;

    img {
        width: 60px;
        height: 80px;
        margin-right: 20px;
    }

    &_content {
        display: flex;
        flex-direction: column;
        width: 80%;
    }

    &_top {
        margin-bottom: 15px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #555;
    }

    &_bottom {
        display: flex;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #555;
    }
}

.targets-delete-modal_checkbox {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    width: 50%;
    height: 37px;
}

.targets-delete-modal_checkbox:checked {
    & + .targets-delete-modal_label::before {
        content: "";
        background-image: url("../../../images/lg-select.svg");
        background-repeat: no-repeat;
        background-position: center;
    }
}

.targets-delete-modal_label {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 160px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #555;
    width: 100%;
    align-items: flex-start;
}


.targets-delete-modal_label::before {
    content: "";
    border: 1px solid #cbcccb;
    border-radius: 4px;
    height: 20px;
    margin-right: 8px;
    padding-right: 18px;
}

