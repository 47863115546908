.wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.items {
    margin-left: 12px;
}

.input-item {
    width: 245px;
}

.input-search {
    width: 100% !important;
}

.label-item {
    position: relative;
    float: left;
    min-height: 1px;
    width: 238px;
    padding: 0 15px;
}

.ml-240 {
    margin-left: 240px;
}

.sub-label {
    padding: 0 15px;
}

.step-3 {
    float: left;
    padding-left: 15px;
    margin-right: 8px;
}

.close-profile {
    position: relative;
    float: right;
    margin-top: -30px;
    margin-right: -25px;
}

.floating {
    float: right;
}

.show-button {
    margin-right: -20px;
}

.checkbox {
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    border-radius: 50%;
    margin-right: 4px;
}

.active {
    border: 6px solid #428bca;
}

.disable {
    border-color: #8C8C8C;
}

.checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.button {
    color: black;
    background-color: #ddd;
    border-color: #ccc;
}
