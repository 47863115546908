.search-filter {
    position: relative;
    width: 40px;
    margin-top: -10px;
    margin-left: -10px;
}

.search-filter:hover > ul {
    display: block;
}

.ico-filter, .search-filter-checkbox {
    background-image: url('../../../images/ico-filter.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
    font-weight: normal;
    display: inline-block;
    padding: 20px;
    margin: -20px 0px -20px 0px;
    cursor: pointer;
}

.search-filter-spacer {
    z-index: 1;
    width: auto !important;
    min-width: 70px;
    top: 30px;
    height: 25px;
    left: -10px;
    display: block;
    position: absolute;
}

.search-filter-spacer:hover ~ ul {
    display: block;
}

.description {
    color: #a1a1a1;
}

.filter-ico-round {
    display: inline-flex !important;
    margin: 0 5px !important;
    vertical-align: baseline !important;
    margin-bottom: -3px !important;
}

.search-filter-checkbox {
    padding: 20px;
    margin: -15px -5px !important;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMHYxMmgxMlYwSDB6bTExLjA3NyAxMS4wNzdILjkyM1YuOTIzaDEwLjE1NHYxMC4xNTR6bS0uOTIzLTcuNjQ0bC0uNjUtLjY2NC00Ljg4OSA1LjEyTDIuNTEgNS41NGwtLjY2NC42MzQgMi43NyAzLjA1OCA1LjUzOC01Ljc5OHoiIGZpbGwtcnVsZT0ibm9uemVybyIgZmlsbD0iIzAwMCIvPjwvc3ZnPg==)
}

.filter-groupn, .filter-group:hover {
    background: initial !important;
    color: #a1a1a1 !important;
    cursor: default !important;
}

.filter-group-description, .filter-group-description:hover {
    color: #a1a1a1 !important;
    cursor: default !important;
}
