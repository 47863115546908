.wrapper {
    position: absolute;
    left: -30px;
    bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.line {
    width: 100%;
    height: 2px;
    background-color: black;
    margin-bottom: 3px;
    transition-duration: .5s;
    transform-origin: 3px 1px 0;
}

.active .line1 {
    transform: rotate(45deg);
}

.active .line2 {
    width: 0;
}

.active .line3 {
    transform: rotate(-45deg);
}

.ico {
    width: 33px;
    height: 20px;
    cursor: pointer;
    margin-bottom: 21px;
    filter: grayscale(100%);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.ico:hover {
    box-shadow: none;
}

.trainings {
    background-image: url('../../../images/v-att.svg');
}

.targets {
    height: 33px;
    margin-bottom: 18px;
    background-image: url('../../../images/v-cdiag.svg');
}

.dashboard {
    height: 24px;
    margin-bottom: 20px;
    background-image: url('../../../images/v-pdiag.svg');
}

.courses {
    margin-bottom: 17px;
    opacity: .8;
    background-size: 73%;
    background-image: url('../../../images/v-edu.svg');
}

.settings {
    opacity: .5;
    background-image: url('../../../images/settings.svg');
}

.scheduler {
    opacity: .7;
    height: 22px;
    margin-bottom: 19px;
    background-image: url('../../../images/v-schdlr.svg');
}

.menu-ico {
    width: 21px;
    height: 10px;
    z-index: 100;
    margin-top: 12px;
    cursor: pointer;
}

.ico-active,
.ico:hover {
    opacity: 1;
    filter: none;
}
.ico-not-active:hover {
    filter: grayscale(100%);
}
