.scheduler-select-match {
    display: flex;
    align-items: center;
    width: auto!important;
}

.scheduler-select-match-text {
    display: inline-block;
    margin-right: 7px;
    width: auto !important;
}

.scheduler-select-match-description {
    display: inline-block;
    margin-right: 7px;
    color: #a1a1a1;
    width: auto!important;
}

.scheduler-svg {
    background: url('../../../images/scheduler.svg') no-repeat;
    width: 21px;
    height: 21px;
}

.scheduler-attack {
    flex-direction: column;
    align-items: flex-start !important;
}

.link {
    color: #4b8fdf;
}

.link:hover {
    color: #1e5ca6 !important;
    box-shadow: 0 1px 0 0 #a2c5ee;
}

.label-link {
    padding-top: 6px !important;
}

.none-padding {
    padding-top: 0 !important;
}

.time-wrapper {
    float: none !important;
    margin-left: -15px;
}

.trigger-group-courses {
    width: 98%;
}

.checked-all {
    width: 12px;
    height: 12px;
    margin-left: 15px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMHYxMmgxMlYwSDB6bTExLjA3NyAxMS4wNzdILjkyM1YuOTIzaDEwLjE1NHYxMC4xNTR6bS0uOTIzLTcuNjQ0bC0uNjUtLjY2NC00Ljg4OSA1LjEyTDIuNTEgNS41NGwtLjY2NC42MzQgMi43NyAzLjA1OCA1LjUzOC01Ljc5OHoiIGZpbGwtcnVsZT0ibm9uemVybyIgZmlsbD0iIzAwMCIvPjwvc3ZnPg==);
}

.btn-ico {
    display: flex;
    flex-wrap: nowrap;
    width: 28px;
    height: 28px;
}
