.modal-form {
    display: grid;
    grid-template-columns: 160px 466px 20px;
    column-gap: 16px;
    margin-bottom: .5rem;
    &_label {
        &__center {
            display: flex;
            align-items: center;
        }
    }
    &_checkbox-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: .5rem;
    }
    &_checkbox {
        margin-right: .5rem;
    }
}

.attachment_button {
    height: auto;
    cursor: pointer;
}

.react-error-wrapper {
    padding: 20px;
    padding-right: 0;
    padding-left: 40px;
}
