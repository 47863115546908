.confirm-popup {
    display: flex;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 360px;
    padding: 16px;
    align-items: center;
    gap: 12px;
    background-color: @popup-color;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(16, 16, 16, 0.08);
    z-index: 200;

    &_delete {
        position: relative;
        width: 40px;
        height: 40px;
        background-color: rgba(255, 255, 255, 0.185);
        border-radius: 50%;
    }

    &_text {
        color: white;
    }

    &_return {
        padding: 6px 12px;
        border-radius: 4px;
        background-color: @popup-element;
        color: white;
        cursor: pointer;

        &:hover {
            background-color: @popup-element-hover;
        }
    }
}
