.password-field {
    width: 300px!important;
}

.label {
    text-align: center;
    margin-bottom: 1.5rem;
}

.qr-code__wrapper {
    display: flex;
    justify-content: center;
    border: 1px solid;
}

.left {
    border-image: linear-gradient(#ccc 5%, transparent 5%, transparent 95%, #ccc 95%)0 0 0 1;
    position: relative;
}

.left::before {
    display: block;
    content: " ";
    position: absolute;
    top: 0;
    border: 1px solid;
    left: -1px;
    border-image: linear-gradient(to right,#ccc 5%,transparent 0,transparent 95%,#ccc 0)1 0 0 0;
    right: -1px;
}

.right {
    border-image: linear-gradient(#ccc 5%, transparent 5%, transparent 95%, #ccc 95%)0 1 0 0;
}

.right::before {
    display: block;
    content: " ";
    position: absolute;
    bottom: 0;
    border: 1px solid;
    left: -1px;
    border-image: linear-gradient(to right,#ccc 5%,transparent 0,transparent 95%,#ccc 0)0 0 1 0;
    right: -1px;
}

.qr-code {
    width: 240px;
}

.secret {
    width: 100%;
    word-break: break-word;
    text-align: center;
    margin: 1.5rem 0;
}

.disabled-button {
    padding-right: 24px !important;
    padding-left: 24px !important;
}
