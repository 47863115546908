@keyframes rot {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rot {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.animation {
    transition: 0.5s linear all;
}

.animation.ng-enter-stagger,
.animation.ng-leave-stagger {
    transiton-delay: 0.1s;
    transition-duration: 0s;
}

.animation.slide-from-left {
    position: relative;
}

.animation.slide-from-left {
    left: 0vw;
}

.animation.slide-from-left:not(:visible) {
    left: +100vw;
}

.animation.ng-enter-active.v1, .animation.ng-leave-active.v1 {
    position: absolute;
}

.animation.slide-from-left.ng-enter {
    left: +100vw;
}

.animation.slide-from-left.ng-enter.ng-enter-active {
    left: 0vw;
}

.animation.slide-from-left.ng-leave {
    left: 0vw;
}

.animation.slide-from-left.ng-leave.ng-leave-active {
    left: -100vw;
}

.modal-open .modal {
    overflow-x: auto;
}

@keyframes alert-hidden {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        display: none;
    }
}

.notification-hidden {
    animation-duration: 1.5s;
    animation-delay: .5s;
    animation-name: alert-hidden;
    animation-fill-mode: forwards;
}
