.device-modal {
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &_label {
        width: 157px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #555555;
        margin-bottom: 4px;
    }

    &_input {
        width: 100%;
        height: 40px;
        border: 1px solid #cbcccb;
        border-radius: 4px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 14px;
        box-shadow: 0px -1px 1px rgb(243 242 242);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        line-height: 1.24rem;
        color: #555;
        appearance: none;
        outline: none;
        font-size: 14px;

        &:active {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
        &:focus {
            box-shadow: 0 0 6px rgb(167 206 238);
        }

        & + .mb {
            margin-bottom: 4px;
        }
    }

    &_block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 16px;
        width: 100%;
    }

    &_bottom {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &_bottom-block {
        display: flex;
        flex-direction: column;
        width: 49%;
    }

    &_bottom-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
    }

    &_pass,
    &_login {
        border: 1px solid #cbcccb;
        border-radius: 4px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 14px;
        box-shadow: 0px -1px 1px rgb(243 242 242);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        line-height: 1.24rem;
        color: #555;
        appearance: none;
        outline: none;
        font-size: 14px;

        &:active {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
        &:focus {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
    }

    &_process {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 20px;
    }

    &_ip-error-none {
        display: none;
    }

    &_ip-error-show {
        display: block;
        color: #a94442;
    }
}

.modal-loader {
    width: 40px;
    height: 40px;
    display: inline;
    margin-left: 48%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.text-error {
    color: #a94442;
}

.input-error {
    border-color: #a94442;
}

