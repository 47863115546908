.manager-table-container {
    margin-left: -15px;
}

.managers-table {
    width: 100% !important;
}

.loader {
    margin-top: 20px;
}

.bottom-container {
    padding: 12px 0 10px 15px;
}

.th-icon {
    vertical-align: text-bottom;
    line-height: initial;
}

.th-icon > ico-round {
    display: inline-block;
    vertical-align: bottom;
}

.th-icon-sortorder, .th-icon-sortorder.reverse {
    vertical-align: bottom;
    line-height: initial;
    margin-right: -11px;
    display: inline-block;
}

.th-icon-sortorder:after, .th-icon-sortorder.reverse:after {
    line-height: initial !important;
    position: initial !important;
    top: initial !important;
    right: initial !important;
    bottom: 0 !important;
    padding: 0 !important;
}

.th-first, .td-first {
    padding-left: 15px;
}

.manager-row {
    line-height: 37px;
}

.manager-name-cell {
    padding-bottom: 3px;
    white-space: nowrap;
}

.manager-num-cell {
    font-size: 21px;
    font-weight: 500;
    text-align: right;
}
