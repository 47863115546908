.list {
    font-size: 14px;
    line-height: 24px;
    color: #333;

    &-name {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        padding-bottom: 4px;
    }

    &_header-wrapper {
        display: grid;
        grid-template-columns: 3.5fr 4fr 2.5fr 2.5fr;
        grid-auto-rows: min-content;
    }
    &_title-wrapper {
        display: flex;
    }
    &_add-link {
        margin-left: auto;
    }

    &_link {
        display: flex;
        align-self: baseline;
    }

    &_arrow {
        border-right: 1.5px solid @multiple-color;
        border-top: 1.5px solid @multiple-color;
        width: 7.5px;
        height: 7.5px;
        margin-right: .5rem;

        &__bottom {
            transform: rotate(135deg);
            align-self: center;
        }
        &__top {
            transform: rotate(-45deg);
            align-self: center;
        }
    }

    &_label {
        padding: 0 8px;
    }

    &_row {
        display: flex;
        padding: 6px 0px;
        &:hover {
            background-color: rgba(0, 0, 0, .04);
            cursor: pointer;
            &>.list_button-sector {
                opacity: 1;
            }
        }
        &__custom {
            display: grid;
            grid-template-columns: 3.5fr 4fr 2.5fr 2.5fr;
            grid-auto-rows: min-content;
        }
    }

    &-title {
        padding: 8px 8px 6px;
        font-weight: 600;
        border-bottom: @border;
    }

    &-item_wrapper {
        border-bottom: @border;
    }

    &_button-sector {
        display: flex;
        align-items: baseline;
        opacity: 0;
        margin-left: auto;
    }

    &_header {
        margin-bottom: 2.3rem;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
    }

    &_icon-wrapper {
        height: 24px;
        display: flex;
        align-items: center;
        margin-left: 1rem;
    }

    &_courses {
        display: flex;
        flex-direction: column;
    }
}

.notification-default_list {
    margin-bottom: 2rem;
}
