.cell-num {
    min-width: 60px;
    box-shadow: none;
    text-align: right;
}

.table-cell {
    text-align: left !important;
}

.int {
    font-size: 21px;
    font-weight: 400;
}

.rating-change {
    font-weight: 500;
    vertical-align: super;
    display: inline-block;
    min-width: 12px;
    text-align: left;
    font-size: 21px;
}

.people-cell {
    min-width: 67px;
    font-size: 13.5px;
    font-weight: 700;
    text-align: right;
}

.people-cell > a {
    color: black;
    box-shadow: none;
}

.targets-wrapper {
    padding-top: 10px;
}

.cell-right {
    text-align: right;
}

.group-row {
    line-height: 36px;
}

.group-row:hover .checkbox, .group-row:hover .action-edit {
    opacity: 1 !important;
}

.group-row:hover {
    background-color: #fcf7e3;
}

.th-icon {
    vertical-align: text-bottom;
    line-height: initial;
}

.th-icon > ico-round {
    display: inline-block;
    vertical-align: bottom;
}

.th-icon-sortorder, .th-icon-sortorder.reverse {
    vertical-align: bottom;
    line-height: initial;
    margin-right: -11px;
    display: inline-block;
}

.th-icon-sortorder:after, .th-icon-sortorder.reverse:after {
    line-height: initial !important;
    position: initial !important;
    top: initial !important;
    right: initial !important;
    bottom: 0 !important;
    padding: 0 !important;
}

.bottom-container {
    padding: 12px 0 10px 60px;
}

.vulns .bottom-container {
    padding: 12px 0 10px 30px;
}

.loader {
    margin-top: 20px;
}

.th-checkbox {
    width: 50px;
    min-width: 50px;
}

.table {
    width: 100%;
}

.th-group-name {
    width: 100%;
    max-width: 615px;
}

.action-edit {
    width: 15px;
    height: 15px;
    opacity: 0;
}

.close-groups {
    margin-right: -7px !important;
    margin-bottom: -7px !important;
}

.table-editable {
    width: calc(100% + 60px) !important;
}

.th-edit {
    width: 40px;
    min-width: 40px;
}

.reports .group-row {
    line-height: 39px;
}

.reports .bottom-container {
    padding: 12px 0 10px 10px;
}

.wrapper {
    padding: .2rem 0;
}

.title {
    display: inline-block;
    font-size: 21px;
    line-height: 2rem;
    margin-bottom: 8px;
}

.link-wrapper {
    margin-bottom: 1.2rem;
}

.sub-groups {
    font-weight: 600;
}

.no-targets {
    color: #777;
}

.progress {
    display: flex;
    height: 35px;
    min-width: fit-content;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    margin: 10px 0
}

.progress-text {
    font-size: 19px;
    line-height: 35px;
}

.item {
    min-width: 30px;
    padding: 0 .79em;
    text-align: right;
}

.miss {
    background-color: #a10bbf;
}

.none {
    height: 36px;
    background-color: #f5f5f5;
    color: rgb(85, 85, 85);
}

.graph-wrapper {
    padding-left: 11px;
}

.graph-counter {
    position: absolute;
    right: 0;
    font-size: 19px
}

.graph-margin {
    margin-bottom: 15px;
}

.graph-cell {
    vertical-align: middle;
}

.vulns .th-checkbox {
    width: 20px;
    min-width: 20px;
}

.vulns .body-checkbox {
    min-width: 20px !important;
}
/*
.a {
    padding: 0 5px 2px 5px;
    margin: 0 -5px -2px -5px;
} */

.dark,
.dark:hover {
    /* padding: 4px 10px; */
    color: #4b8fdf;
}

.light,
.light:hover {
    /* padding: 4px 10px; */
    color: white;
}

.action-delete {
    width: 15px;
    height: 15px;
    background-size: 88%;
    margin-left: 10px;
    opacity: 0;
}

.group-row:hover .checkbox, .group-row:hover .action-delete {
    opacity: 1 !important;
}

.action-cell {
    display: flex;
}

.body-checkbox {
    padding-right: 0;
}
