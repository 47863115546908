.mr-1 {
    margin-right: .3rem;
}

.pt-0 {
    padding-top: 0 !important;
}

.mr-15 {
    margin-right: 15px;
}

.mr-30 {
    margin-right: 30px !important;
}
