.checkbox_wrapper {
	display: inline-block;
	background-image: url(../images/sm_empty.svg);
	background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
    vertical-align: middle;
}

.checkbox_wrapper>input {
	opacity: 0;
}

.sm-checkbox {
	width: 12px;
	height: 12px;
}

.lg-checkbox {
	width: 14px;
	height: 14px;
}

.checkbox-parent .checkbox_wrapper {
	opacity: 0;
}

.checkbox-parent:hover .checkbox_wrapper {
	opacity: 1;
}

.checkbox_wrapper.checked {
	background-image: url(../images/lg_selected.svg);
	opacity: 1;
}

.checkbox_wrapper.partial {
	background-image: url(../images/lg_partial.svg);
	opacity: 1;
}

.languages-ckeckbox {
    background-image: url(../images/sm_empty.svg);
}

.languages-checked {
    background-image: url(../images/lg_selected.svg);
}
