.close-profile {
    position: relative;
    float: right;
    margin-top: -30px;
    margin-right: -25px;
}
.label-item {
    position: relative;
    float: left;
    min-height: 1px;
    width: 238px;
    padding: 0 15px;
}
