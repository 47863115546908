.file-save {
    position: relative;

    &_items {
        display: block;
        position: absolute;
        top: 100%;
        left: -21px;
        padding: 5px 0;
        margin: 2px 0 0;
        max-height: 200px;
        overflow-y: auto;
        border: @border;
        border-radius: 4px;
        box-shadow: 0 6px 12px rgb(0 0 0 / 18%);

        background-color: #fff;
        font-size: 13.5px;
    }

    &_link {
        margin-right: 0.3rem;
    }

    &_list-item {
        padding: 3px 20px;
        color: @title-color;
        white-space: nowrap;
        line-height: 1.4;
        cursor: pointer;

        &:hover {
            color: #262626;
            background-color: #f5f5f5;
        }
    }
}
