:root {
    --light-background-color: white;
    --light-color: black;
    --light-icon-fill: #37397F;
    --light-link-color: #4b8fdf;
    --light-drop-item: #f5f5f5;
    --light-list-item: inherit;
    --light-cross: black;
    --light-border-color: #ccc;

    --dark-background-color: black;
    --dark-color: rgb(247, 247, 247);
    --dark-icon-fill: #fff;
    --dark-link-color: #4b8fdf;
    --dark-drop-item: #333;
    --dark-list-item: #fff;
    --dark-cross: white;
    --dark-fill-mode: #fff;
    --dark-border-color: #ccc;
}

.preview {
    &_icon-wrapper {
        width: 80px;
        margin-bottom: 15px;
    }

    &_section-header {
        margin-bottom: 15px;
        font-size: 19px;
        font-weight: bold;
    }

    &_submit-wrapper {
        padding-top: 10px;
    }

    &_submit-button {
        outline-color: transparent;
        position: relative;
        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        font-weight: 400;
        box-sizing: border-box;
        border: 1px solid rgb(0, 120, 212);
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        padding: 0 16px;
        border-radius: 2px;
        min-width: 80px;
        height: 32px;
        background-color: rgb(0, 120, 212);
        color: rgb(255, 255, 255);
    }

    &_sub-text-wrapper {
        margin-bottom: 15px;
        font-size: 14px;
    }

    &_title {
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    &_parent-wrapper {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        min-height: 41px;
        margin-bottom: 0px;
        border: 1px solid var(--light-border-color);
        border-radius: 5px;
        padding-right: 16px;

        &:last-child {
            margin-bottom: 25px;
        }
    }

    &_dropdown-item-arrow {
        position: absolute;
        right: 15px;
        margin-top: 9px;
    }

    &_dark-mode-fill {
        color: var(--dark-fill-mode);
        fill: var(--dark-fill-mode);
    }

    &_list-item {
        display: flex;
        align-items: center;
        margin: 5px;
        padding: 5px;
        border-radius: 5px;
    }

    &_list-item__text {
        margin-right: 5px;
    }

    &_cross {
        display: inline-block;
        position: relative;
        min-width: 10px;
        min-height: 10px;

        &::after,
        &::before {
            display: inline-block;
            position: absolute;
            top: 6px;
            content: ' ';
            background-color: var(--light-cross);
            width: 10px;
            height: 1px;
        }

        &::before {
            transform: rotate(-45deg);
        }

        &::after {
            transform: rotate(45deg);
        }
    }

    &_sub-text-include {
        margin-bottom: 10px!important;
    }

    &_source-text {
        display: inline-flex;
        margin-bottom: 25px;
    }
}

.lnk-preview {
    width: 32px;
    height: 32px;
    margin-right: .7rem;
}

.lnk-drop {
    display: flex;
    align-items: center;

    &_text {
        display: flex;
        flex-direction: column;
        margin-right: auto;
    }
}
