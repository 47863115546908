.table-wrapper {
    margin-left: -60px;
}

.table {
    width: 100%;
}

.table-header {
    line-height: 37px;
    color: black;
    font-weight: 700;
}

.header-cell {
    padding: 0 10px;
    border-bottom: 1px solid #eaeaea;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
}

.header-link {
    display: inline-block;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
}

.header-link:hover {
    color: inherit;
    text-decoration: none;
    box-shadow: none;
}

.header-link:active {
    color: inherit;
}

.body-cell {
    padding: 0 10px;
    vertical-align: baseline;
}

.header-ico-cell {
    text-align: right !important;
}

.body-checkbox {
    position: relative;
    min-width: 50px;
}

.body-cell-num {
    font-size: 21px;
    font-weight: 500;
    text-align: right;
}

.department_count {
    color: #555;
}

.risk-informer > table > tbody > tr > td > ul > li {
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    padding: 0;
    line-height: 50px;
}

.risk-informer .department__table {
    width: auto;
    margin: 0;
}

.risk-informer .department__table tbody td:first-child {
    padding-left: 0;
    padding-right: 0;
}

.risk-informer .department__table tbody td:first-child .department__target-checkbox-wrapper {
    right: 7px;
}

.risk-informer .department__table .department__list {
    width: auto;
    margin: 0 !important;
}

.risk-informer .department__list thead th:nth-child(2),
.risk-informer .department__list tbody td:nth-child(2) {
    padding-left: 0 !important;
}

.risk-informer .department__target-checkbox-wrapper {
    margin: 0 auto !important;
}

.risk-informer .department__table tbody .department td:first-child {
    text-align: center !important;
}

.risk-informer .department__table .department {
    margin: 0;
}

.risk-informer .department__table tr.department + tr table {
    padding-bottom: 5px;
    display: block;
}

.risk-informer .department__table th {
    white-space: nowrap;
}

.risk-informer .department__title {
    padding-right: 1.5rem !important;
}

.risk-informer__recomendation {
    padding-top: 15px;
    padding-bottom: 15px;
}

.risk-informer .rating {
    position: relative;
}

.risk-informer .rating::after {
    bottom: 0;
    top: 0;
    content: " ";
    display: block;
    width: 4px;
    left: 50%;
    margin-left: -2px;
    position: absolute;
}

.risk-informer .marker-purple::after {
    background-color: #BD10E0;
}

.risk-informer .marker-yellow::after {
    background-color: #F5A623;
}

.risk-informer .marker-red::after {
    background-color: #D95350;
}

.risk-informer__software-info {
    display: table-row;
}

.risk-informer__counter {
    display: table-cell;
    padding-right: 20px;
    padding-left: 10px;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
}

.risk-informer__counter--inner {
    text-align: center;
    width: auto;
    min-width: 30px;
    display: table-cell;
}

.risk-informer__browser-ico--wrapper {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.risk-informer__browser-version {
    padding-left: 15px;
    padding-right: 5px;
    display: table-cell;
    vertical-align: middle;
}

.risk-informer__header table {
    width: 100%;
}

.risk-informer__header table tr th {
    color: black;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 600;
    vertical-align: top;
    padding: 0;
    line-height: 37px;
}

.risk-informer__header table tr th:nth-child(1) {
    width: 60px;
}

.risk-informer__header table tr th:nth-child(2) {
    width: 50px;
}

.risk-informer__header table tr th:nth-child(3) {
    width: 390px;
}

.risk-informer__header table tr th:nth-child(4) {
    width: 100px;
    text-align: center;
}

.risk-informer__header table tr th:nth-child(5) {
    width: 400px;
}

.risk-informer {
    border-top: 1px solid #CCC;
    padding-top: 9px;
    padding-top: 0.62rem;
    padding-bottom: 9px;
    padding-bottom: 0.62rem;
}

.risk-informer > table {
    width: 100%;
}

.risk-informer > table > tbody > tr > td {
    vertical-align: top;
    display: table-cell;
}

.risk-informer > table > tbody > tr > td:nth-child(1) {
    width: 500px;
}

.risk-informer > table > tbody > tr > td:nth-child(2) {
    width: 100px;
}

.risk-informer > table > tbody > tr > td:nth-child(3) {
    width: 400px;
}

.risk-informer > table > tbody > tr > td.rating::after {
}

.risk-informer > table > tbody > tr > td.rating.critical::after {
    background-color: #BD10E0;
}

.risk-informer > table > tbody > tr > td.rating.major::after {
    background-color: #D95350;
}

.risk-informer > table > tbody > tr > td.rating.minor::after {
    background-color: #F5A623;
}

.checkbox-cell {
    vertical-align: baseline;
    padding: 0;
}
