.wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    bottom: 31px;
    left: 0;
    max-width: 257px;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    z-index: 1;
    box-sizing: content-box;
    //display: none;
}

.header-text {
    display: flex;
}

.text {
    white-space: nowrap;
    margin-right: .3rem;
}

.save {
    margin-top: 1.24rem;
}
