.progress {
    display: flex;
    height: 35px;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    min-width: 30px;
    min-width: fit-content;
}

.item {
    min-width: 30px;
    padding: 0 .79em;
    text-align: right;
}

.progress-text {
    min-width: 30px;
    min-width: fit-content;
    font-size: 19px;
    line-height: 35px;
}

.miss {
    background-color: #a10bbf;
}

.none {
    height: 36px;
    background-color: #f5f5f5;
    color: rgb(85, 85, 85);
}

.complete {
    background-color: #5cb85c;
}

.process {
    background-color: #f0ad4e;
}

.negativeRatingDown {
    background-color: #CE1916;
}

.negativeRatingUp {
    background-color: #D95250;
}

.positiveRatingDown {
    background-color: #5DB75C;
}

.positiveRatingUp {
    background-color: #1D9C1B;
}

.noRatingChanges {
    background-color: #F5F5F5;
    color: rgb(85, 85, 85);
}
