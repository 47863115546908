.icon-wrapper {
    display: inline-block;
    text-decoration: none;
    box-shadow: none !important;
    vertical-align: text-bottom;
}

.schema-icon {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50px;
}

.new-target-ico {
    border: 1px solid #ddd;
}

.catch-attack-ico {
    border: 1px solid #5CB75C;
}

.danger-ico {
    background-color: #d95350;
}

.succsess-ico {
    background-color: #5CB75C;
}

.warning-ico {
    background-color: #f5a623;
}

.time-out-ico {
    background-color: #BD10E0;
}

.learning-ico {
    background-image: linear-gradient(135deg, black 26%, transparent 26%, transparent 37%, black 38%, black 50%, transparent 53%, transparent 62%, black 62%, black 78%, transparent 78%);
}

.miss-learning {
    background-image: linear-gradient(135deg, black 26%, #BD10E0 26%, #BD10E0 37%, black 38%, black 50%, #BD10E0 53%, #BD10E0 62%, black 62%, black 78%, #BD10E0 78%);
}

.complete-learning {
    background-image: linear-gradient(135deg, black 26%, #5CB75C 26%, #5CB75C 37%, black 38%, black 50%, #5CB75C 53%, #5CB75C 62%, black 62%, black 78%, #5CB75C 78%);
}

.no-learning {
    opacity: .3;
}

.arrow {
    display: block;
    content: '\2193';
    width: 15px;
    height: 16px;
}

.report-mail-ico {
    border: 2px solid #217c0b;
}

.vuln-ico {
    border: 2px solid #bd0fe1;
}

.learning-proccess {
    background-color: #f0ad4e;
}