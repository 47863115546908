.wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
}

.item-wrapper {
    display: flex;
    flex-direction: column;
}

.ldap-profile {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.ldap-profile:last-child {
    margin-bottom: 0;
}

.label {
    display: inline-flex;
    align-self: flex-start;
    align-items: center;
    justify-content: end;
    width: 22.3%;
    height: 40px;
    margin-right: 29px;
    color: #555;
}

.item-self {
    width: 35%;
    margin-right: 10px;
}

.item-groups {
    align-items: baseline;
}

.add-ico {
    position: relative;
    display: flex;
    opacity: .5;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    border: 1px solid #010101;
    border-radius: 50%;
    min-width: 25px;
    min-height: 25px;
}

.add-ico::before,
.add-ico::after {
    position: absolute;
    display: block;
    content: '';
    background-color: black;
}

.add-ico::before {
    height: 11px;
    width: 1px;
}

.add-ico::after {
    width: 11px;
    height: 1px;
}

.empty {
    height: 0px;
}

.sync-wrapper {
    margin-top: 1.5rem;
}

.sync-period-wrapper {
    display: flex;
    flex-direction: column;
}

.period-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.period-item:last-child {
    margin-bottom: 0;
}
