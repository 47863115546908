.react-modal-disabled {
    display: flex;
    align-items: center;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 15px;
    padding-bottom: 15px;

    &_descr {
        text-align: center;
        margin-bottom: 1.5rem;
    }
}

.btn-modal-disable {
    margin-right: 1em;
    width: 60px;
}
