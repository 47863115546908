.cancel-modal {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 2rem;
    &_header {
        display: flex;
        width: 100%;
        justify-content: right;
    }
    &_body {
        width: 100%;
        padding: 1rem 1rem 0 0;
    }
    &_footer {
        display: flex;
        width: 100%;
        justify-content: right;
    }
    &_wrapper {
        display: flex;
    }
    &_close {
        font-size: 21px;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        filter: alpha(opacity=20);
        opacity: 0.2;
        background-color: transparent;
        outline: none;
        border: none;
    }
    &_icon {
        width: 64px;
        height: 77px;
        margin-right: 2rem;
        background-image: url("../../../images/play_icons/basket_lg.svg");
        background-position: center;
        background-repeat: no-repeat;
    }
    &_text {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        color: @assets-text-color;
    }
    &_text-item {
        &:first-child {
            margin-bottom: 1rem;
        }
    }

    &_button {
        margin-left: 1rem;
    }
}
