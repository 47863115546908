.react-tooltip {
    position: absolute;
    display: none;
    left: 50%;
    transform: translate(-50%, -120%);
    border-radius: 4px;
    padding: 3px 8px;
    width: max-content;
    background-color: @assets-color;
    color: white;
    font-size: 12px;
    line-height: 1.4;
    text-align: center;
    z-index: 100;

    &::after {
        display: block;
        content: "";
        position: absolute;
        left: 50%;
        border: 4px solid black;
        transform: translate(-50%, -20%) rotate(45deg);
    }
}

.react-tooltip-wrapper {
    display: flex;
    position: relative;
    align-items: center;
    width: fit-content;

    &:hover>.react-tooltip {
        display: block;
    }
}
