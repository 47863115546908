.wrapper {
    display: flex;
    gap: 99px;
    padding-top: 16px;
    padding-left: 45px;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    margin-left: 10px;
    margin-right: 1px;
    position: relative;
}

.title {
    color: #000;
    display: inline-block;
    padding: 0;
    margin-bottom: 5px; 
    font-size: 22px;
    line-height: 37px;
}

.title::after {
    content: "";
    display: block;
    position: relative;
    top: -5px;
    width: 100%;
    height: 1px;
    opacity: 0;
    background-color: #000;
}
  
.title:not(.active):hover::after {
    opacity: 0.3;
}

.active {
  font-weight: 600;
}

.disabled {
  cursor: default !important;
}

.disabled::after {
  display: none;
}


.rectangle {
    position: relative;
    margin-bottom: 12px;
    width: 155px;
    height: 100px;
    background-color: rgba(204, 204, 204, 1);
    overflow: hidden;
}

.rectangle::after {
    content: '';
    width: 100px;
    height: 100Px;
    background: linear-gradient(90deg,rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0.48) 50%, rgba(255, 255, 255, 0) 90%); 
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: rectangle 2s linear infinite;
}

.rectangleSmall {
    box-sizing: border-box;
    position: absolute;
    left: 121px;
    top: 53px;
    width: 49px;
    height: 35px;
    box-shadow: 0px 6px 13px 5px rgba(0,0,0,0.14);
    background-color: rgba(204, 204, 204, 1);
    z-index: 1;
    overflow: hidden;
}

.rectangleSmall::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 35px;
    animation: rectangle 2s linear infinite;
    background: linear-gradient(90deg,rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0.40) 50%,rgba(255, 255, 255, 0) 100%); 
}

.textstub {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
    margin-top: 2px;
    position: relative;
    overflow: hidden;
}
  
.textstub::after {
    content: '';
    width: 60px;
    height: 30Px;
    background: linear-gradient(90deg,rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0.48) 50%,rgba(255, 255, 255, 0)  100%); 
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: textstub 2s linear infinite;
}
  
.textstub div {
    height: 14px;
    border-radius: 4px;
    width: 100%;
    display: inline-block;
    position: relative;
    background: rgba(204, 204, 204, 1);
    overflow: hidden;
}
  
.textstub div:nth-child(2) {
    width: 50%;
}
  

@keyframes rectangle {
    0% {
      left: 0;
      transform: translateX(-100%);
    }
    100% {
      left: 100%;
      transform: translateX(0%);
    }
}

@keyframes textstub {
    0% {
      left: 0;
      transform: translateX(-100%);
    }
    100% {
      left: 100%;
      transform: translateX(0%);
    }
}
