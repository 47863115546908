.savefile-popup {
    position: absolute;
    bottom: 31px;
    right: -50px;
    max-width: 257px;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    z-index: 1;
    display: none;

    &_calendar-start {
        display: inline-block;
        margin-right: 5px;
    }

    &_calendar-end {
        display: inline-block;
    }

    &_period {
        background-color: transparent;
        border: none;
        color: #4b8fdf;
        padding: 0;
        &:hover {
            color: #4b8fdf;
            border-bottom: none;
            box-shadow: 0 1px 0 0 #a2c5ee;
        }
    }

    &_date {
        background-color: transparent;
        border: none;
        color: #4b8fdf;
        padding: 0;
        &:hover {
            color: #4b8fdf;
            border-bottom: none;
            box-shadow: 0 1px 0 0 #a2c5ee;
        }
    }

    &_content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-link {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
        }
    }

    &_link {
        width: 80px;
        color: #4b8fdf;
        &:hover {
            color: #4b8fdf;
            border-bottom: none;
            box-shadow: 0 1px 0 0 #a2c5ee;
        }
    }

    &::before {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        display: inline-block;
        transform: rotate(45deg);
        border-bottom: 1px solid #ccc;
        border-right: 1px solid #ccc;
        right: 90px;
        top: 93%;
        left: 47%;
        background-color: #fff;
    }
}

.savefile-popup-allTime {
    position: absolute;
    bottom: 31px;
    right: -50px;
    max-width: 257px;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    z-index: 1;
    display: none;

    &::before {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        display: inline-block;
        transform: rotate(45deg);
        border-bottom: 1px solid #ccc;
        border-right: 1px solid #ccc;
        right: 90px;
        top: 90%;
        left: 47%;
        background-color: #fff;
    }
}

.popup-active,
.popup-active-allTime {
    display: flex;
    justify-content: center;
}


