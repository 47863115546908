.sceleton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 156px;
  gap: 11.5px;
}

.title {
  color: #000;
  display: inline-block;
  padding: 0;
  margin-bottom: -1px;
  font-size: 22px;
  line-height: 37px;
}

.title::after {
  content: "";
  display: block;
  position: relative;
  top: -5px;
  width: 100%;
  height: 1px;
  opacity: 0;
  background-color: #000;
}

.title:not(.active):hover::after {
  opacity: 0.3;
}

.active {
  font-weight: 600;
}

.disabled {
  cursor: default !important;
}

.disabled::after {
  display: none;
}

.circle {
  width: 155px;
  height: 155px;
  border-radius: 50%;
  display: block;
  position: relative;
  background: conic-gradient(#e4e4e4, #cccccc);
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
.circle::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 131.5px;
  height: 131.5px;
  border-radius: 50%;
  background: #FFFFFF;
}  

.textstub {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  margin-top: 2px;
  position: relative;
  overflow: hidden;
}

.textstub::after {
  content: '';
  width: 60px;
  height: 30Px;
  background: linear-gradient(90deg,rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0.48) 50%,rgba(255, 255, 255, 0)  100%); 
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: textstub 2s linear infinite;
}

.textstub div {
  height: 14px;
  border-radius: 4px;
  width: 100%;
  display: inline-block;
  position: relative;
  background: rgba(204, 204, 204, 1);
  overflow: hidden;
}

.textstub div:nth-child(2) {
  width: 50%;
}

@keyframes textstub {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}
  
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 