.static-notification {
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 4px;

    &__warning {
        color: #8a6d3b;
        background-color: #fcf8e3;
        border-color: #faebcc;
    }
}
