.item {
    width: 100%;
}

.item:hover .hidden {
    display: flex;
    justify-content: flex-end;
    padding-right: 5px;
}

.wrapper {
    width: 75%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 1rem 0;
    border-top: 1px solid #dfdfdf;
}

.main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
}

.name-row {
    display: flex;
    align-items: center;
}

.attack-state {
    font-size: 14px;
}

.buttons {
    min-width: 205px;
}

.ico-wrapper {
    width: 34px;
    height: 34px;
    margin-left: 5px;
}

.ico {
    width: 34px;
    height: 34px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.ico-min {
    width: 20px;
}

.hidden {
    display: none;
}

.right-path {
    position: absolute;
    right: calc(25% - 100px);
    display: flex;
    width: 100px;
}

.right-time {
    position: absolute;
    left: 115%;
    top: 25%;
    width: auto;
    white-space: nowrap;
}

.time-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.left-time {
    padding-right: 16px;
    white-space: nowrap;
}
