.react-modalsso {
    display: flex;
    flex-direction: column;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 15px;
    padding-bottom: 15px;

    &_block {
        display: flex;
        margin-bottom: 1.24rem;
        min-height: 40px;
        align-items: baseline;
    }

    &_error{
        display: flex;
        align-items: center;
        border-top: 1px solid #e5e5e5;
    }

    &_label {
        width: 30%;
        text-align: right;
        margin-right: 1rem;
    }

    &_input {
        width: 350px;
        padding: 6px 12px;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0px -1px 1px rgb(243 242 242);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        font-size: 0.92rem;
        line-height: 1.24rem;
        color: #555;
        appearance: none;
        outline: none;
        height: 40px;

        &:active {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
        &:focus {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
    }

    &_span {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border: 1px solid #adadad;
        color: #333;
        background-color: #e6e6e6;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .13);
        padding: 6px 12px;
        font-size: 14px;
        font-weight: normal;
        min-height: 40px;
    }

    &_url {
        width: 350px;
        display: flex;
    }
}

.input-url {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-error {
    border-color: #a94442;
}
