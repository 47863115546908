.react-popup {
    display: flex;
    align-items: center;
    padding: 16px;
    background-color: #1c1c1c;
    width: 360px;
    height: 72px;
    box-shadow: 0px 4px 12px rgba(16, 16, 16, 0.08);
    border-radius: 8px;
}
