.react-user-modal {
    padding-left: 5em;
    padding-right: 11em;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    max-width: 100%;

    &_clean-ldap {
        position: absolute;
        top: 20%;
        right: -7%;
        margin-left: 15px;
        font-size: 21px;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: 0.2;
        cursor: pointer;
        background-color: transparent;
        outline: none;
        border: none;
        &:hover {
            color: #000;
            text-decoration: none;
            opacity: 0.5;
        }



    }

    &_clean-sso {
        position: absolute;
        top: 20%;
        right: 38%;
        margin-left: 15px;
        font-size: 21px;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: 0.2;
        cursor: pointer;
        background-color: transparent;
        outline: none;
        border: none;


    }

    &_calendar {
        background: none;
        border: none;
        color: #4b8fdf;

        &:hover {
            color: #1e5ca6;
            box-shadow: 0px 1px 0px 0px rgb(162 197 238);
        }
    }

    &_loader {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-top: 20px;
        margin-bottom: 20px;

        img {
            width: 45px;
            height: 45px;
        }
    }

    &_ldap {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;

        span {
            margin-right: 15px;
            margin-left: 15px;
        }
    }

    &_block {
        display: flex;
        margin-bottom: 1.24rem;
        min-height: 40px;
        align-items: baseline;
    }

    &_checkblock {
        margin-bottom: 1rem;
    }

    &_label {
        width: 30%;
        text-align: right;
        margin-right: 30px;
    }

    &_input {
        width: 100%;
        padding: 6px 12px;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0px 0px 1px 1px #f1f1f1;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        font-size: 0.92rem;
        line-height: 1.24rem;
        color: #555;
        appearance: none;
        outline: none;
        height: 40px;

        &:active {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
        &:focus {
            box-shadow: 0 0 6px rgb(167 206 238);
        }

        &.input-pass {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
    }

    &_none {
        display: none;
    }

    &_checkbox {
        padding-left: 26%;
        padding-top: 10px;
    }

    &_password {
        display: flex;
        width: 100%;
    }

    &_password-block {
        display: flex;
        align-items: center;
    }

    &_btn {
        border: 1px solid #ccc;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        border-left-color: transparent;
    }

    &_link {
        color: #4b8fdf;

        &:hover {
            color: #1e5ca6;
            box-shadow: 0px 1px 0px 0px rgb(162 197 238);
        }
    }

    &_label-link {
        width: 30%;
        text-align: right;
        margin-right: 30px;
        color: #4b8fdf;
        transition: color 0.2s ease-in-out;

        &:hover {
            color: #1e5ca6;
        }
    }

    &_label-link-sso {
        width: 22%;
        text-align: right;
        margin-right: 30px;
        color: #4b8fdf;
        transition: color 0.2s ease-in-out;

        &:hover {
            color: #1e5ca6;
        }
    }

    &_select {
        width: 100%;
    }

    &_select-depart {
        width: 484px;
        padding: 6px 12px;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0px 0px 1px 1px #f1f1f1;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        font-size: 0.92rem;
        line-height: 1.24rem;
        color: #555;
        appearance: none;
        outline: none;
        height: 40px;

        &:active {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
        &:focus {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
    }

    &_options {
        position: absolute;
        top: 0;
        left: 0;
        width: 25%;
        font-size: 0.92rem;
        line-height: 1.24rem;
        color: #555;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    &_select-sso {
        width: 30%;
    }

    &_calendar {
        max-width: 100%;
    }

    &_clean-block {
        position: relative;
    }
}

.checkox-input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
}

.checkox-input:checked {
    & + .checkbox-label::before {
        content: "";
        background-image: url("../../../images/lg_selected.svg");
    }
}

.checkbox-label {
    cursor: pointer;
    display: flex;
    align-items: center;

    pre {
        background: none;
    }
}

.checkbox-label::before {
    content: "";
    background-image: url("../../../images/lg_empty.svg");
    width: 14px;
    height: 14px;
    margin-right: 7px;
}

.error-label {
    color: #a94442;
}

.error-input {
    border-color: #a94442;
    &:active {
        box-shadow: 0 0 6px #ce8483;
    }
    &:focus {
        box-shadow: 0 0 6px #ce8483;
    }
}

.user-modal_select__control {
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 0px 1px 1px #f1f1f1;
    height: 40px;
    font-size: 0.92rem;
    line-height: 1.24rem;
    color: #555;
}

.user-modal_select__indicator-separator {
    display: none;
}

.user-modal_select__dropdown-indicator {
    display: none;
}

.user-modal_select__menu {
    width: 100px;
}

.user-modal_select__value-container {
    display: flex !important;
    padding: 0 !important;
    padding-left: 5px !important;
}

.user-modal_select-role__indicators {
    display: none !important;
}
