.footer-page {
    position: relative;
    .row();

    font-size: .92rem;
    padding: 1.86rem 0;

    &_project {
        .col();
        .size(4.5);

        color: @assets-text-color;
    }

    &_language {
        .col();
        .size(3);
        text-align: center;
    }

    &_support {
        .col();
        .size(4.5);
        text-align: right;

        color: @assets-text-color;
    }
}
