.react-modalqr {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 15px;
    padding-bottom: 15px;

    &_descr {
        text-align: center;
        margin-bottom: 1.5rem;
    }

    &-form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &_qr-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid;
    }

    &_number {
        font-size: 0.92rem;
        line-height: 1.24rem;
        color: #555;
        appearance: none;
        outline: none;
        height: 40px;
        width: 300px;
        padding: 6px 12px;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0px -1px 1px rgb(243 242 242);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button {
            display: none;
        }

        &:active {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
        &:focus {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
    }

    &_qr-number {
        width: 100%;
        word-break: break-word;
        text-align: center;
        margin: 1.5rem 0;
    }

    &_qr-code {
        width: 240px;
    }

    &_error {
        display: flex;
        align-items: center;
        border-top: 1px solid #e5e5e5;
    }
}

.border-right {
    border-image: linear-gradient(#ccc 5%, transparent 5%, transparent 93%, #ccc 95%) 0 1 0 0;

    &::before {
        display: block;
        content: " ";
        position: absolute;
        bottom: 0;
        border: 1px solid;
        left: -1px;
        border-image: linear-gradient(to right, #ccc 5%, transparent 0, transparent 95%, #ccc 0) 0 0 1 0;
        right: -1px;
    }
}

.border-left {
    border-image: linear-gradient(#ccc 5%, transparent 5%, transparent 95%, #ccc 95%) 0 0 0 1;
    position: relative;

    &::before {
        display: block;
        content: " ";
        position: absolute;
        top: 0;
        border: 1px solid;
        left: -1px;
        border-image: linear-gradient(to right, #ccc 5%, transparent 0, transparent 95%, #ccc 0) 1 0 0 0;
        right: -1px;
    }
}
