.button-group {
	align-items: center !important;
}

.timeout {
	background-color: transparent !important;
}

.reverse {
    display: inline-block;
    vertical-align: middle;
    transform: rotate(180deg);
}

.wrapper {
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    padding: 8px 13px;
    width: 100%;
    flex-wrap: wrap;
}

.chips-text {
    margin-right: 5px;
}

.chips-item {
    display: flex;
    border-radius: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    background-color: #F2F2F2;
    cursor: default;
}

.chips-cross {
    cursor: pointer;
}

.placeholder {
    font-size: 15px;
    color: #8f8f8f;
    cursor: pointer;
}

.chips-menu {
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.24);
    padding: 6px 0;
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 485px;
    top: 100%;
    z-index: 100;
}

.select-item {
    padding: 5px 10px;
}

.select-item:hover {
    background-color: #F5F5F5;
    cursor: pointer;
}

.group {
    margin: 0 -15px 14px;
}

.group::after {
    clear: both;
    content: ' ';
    display: block;
}

.menu-wrap {
    position: relative;
}
