.wifi-basket {
    &_toolbar {
        position: sticky;
        top: 17%;
        z-index: 1;
        width: 100%;
        padding: 16px 0;
        left: 0;
        background-color: #fff;

        &-content {
            max-width: 68rem;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            margin-bottom: 8px;
            align-items: center;
        }
    }

    &_btn {
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        border: none;
        margin-right: 8px;
        padding: 6px 12px;
        color: rgba(0, 0, 0, 0.38);
        font-size: 14px;
        cursor: default;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

        &-delete {
            width: 14px;
            height: 14px;
            stroke: #aeaeae;
            fill: none;
            margin-right: 6px;
            transition: stroke 0.2s ease-in-out;
        }

        &-restore {
            width: 15px;
            height: 14px;
            stroke: #aeaeae;
            fill: none;
            margin-right: 5px;
            transition: stroke 0.2s ease-in-out;
        }
    }

    &_active-btn {
        color: #333;
        cursor: pointer;

        &:hover,
        &:active {
            background-color: rgba(0, 0, 0, 0.12);
        }
    }

    &_active-icon {
        stroke: #333;
    }
}