.wrapper {
    display: flex;
    width: 100%;
    align-items: center;
}

.item {
    margin-right: 10px;
}

.button-wrapper {
    margin-left: auto;
    margin-right: 13px;
}

.search-elem {
    width: 245px !important;
    box-sizing: border-box;
}

.unselect-all-link {
    margin-left: 5px;
}

.before-load {
    display: flex;
    align-items: baseline;
}

.search-elem-before {
    margin-left: 15px;
}
