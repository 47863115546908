.select-language {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

    &_options {
        width: 120px;
        position: absolute;
        display: none;
        flex-direction: column;
        bottom: 2rem;
        padding: 5px 0;

        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);

        &:hover {
            display: flex;
        }
    }

    &:hover > &_options {
        display: flex;
    }

    &_item {
        padding: 3px 20px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        cursor: pointer;

        line-height: 20px;

        &:hover {
            background-color: #428bca;
            color: #ffffff;
        }
    }

    &_value {
        display: flex;
        align-items: center;
        padding-top: 20px;
        margin-top: -20px;
        &:hover {box-shadow: none;}
    }

    &_flag {
        width: 14px;
        height: 14px;
        display: inline-block;
        margin-right: 8px;
    }

    &_ru {
        background-image: url("../../../images/ru.svg");
    }

    &_en {
        background-image: url("../../../images/en.svg");
    }

    &_it {
        background-image: url("../../../images/it.svg");
    }
}
