@charset "UTF-8";
.flag {
    padding-top: 0px;
    background-image: url(../images/all-flags-sprite.png);
    background-repeat: no-repeat;
    background-position: -120px -192px;
    display: inline-block;
    height: 12px;
    width: 20px;
    transform: scale(0.75);
    transform-origin: top left;
    -moz-transform: scale(0.75, 0.75);
    -moz-transform-origin: top left;
    position: relative;
    top: 18px;
}
.AF {background-position:   0px 0px;}
.AL {background-position: -20px 0px;}
.DZ {background-position: -40px 0px;}
.AS {background-position: -60px 0px;}
.AD {background-position: -80px 0px;}
.AO {background-position: -100px 0px;}
.AI {background-position: -120px 0px;}
.AG {background-position: -140px 0px;}
.AR {background-position: -160px 0px;}
.AM {background-position: -180px 0px;}
.AW {background-position:   0px -12px;}
.AU {background-position: -20px -12px;}
.AT {background-position: -40px -12px;}
.AZ {background-position: -60px -12px;}
.BS {background-position: -80px -12px;}
.BH {background-position: -100px -12px;}
.BD {background-position: -120px -12px;}
.BB {background-position: -140px -12px;}
.BY {background-position: -160px -12px;}
.BE {background-position: -180px -12px;}
.BZ {background-position:   0px -24px;}
.BJ {background-position: -20px -24px;}
.BM {background-position: -40px -24px;}
.BT {background-position: -60px -24px;}
.BO {background-position: -80px -24px;}
.BA {background-position: -100px -24px;}
.BW {background-position: -120px -24px;}
.BR {background-position: -140px -24px;}
.VG {background-position: -160px -24px;}
.BN {background-position: -180px -24px;}
.BG {background-position:   0px -36px;}
.BF {background-position: -20px -36px;}
.BI {background-position: -40px -36px;}
.KH {background-position: -60px -36px;}
.CM {background-position: -80px -36px;}
.CA {background-position: -100px -36px;}
.CV {background-position: -120px -36px;}
.KY {background-position: -140px -36px;}
.CF {background-position: -160px -36px;}
.TD {background-position: -180px -36px;}
.CL {background-position:   0px -48px;}
.CN {background-position: -20px -48px;}
.CO {background-position: -40px -48px;}
.KM {background-position: -60px -48px;}
.CD {background-position: -80px -48px;}
.CG {background-position: -100px -48px;}
.CK {background-position: -120px -48px;}
.CR {background-position: -140px -48px;}
.CI {background-position: -160px -48px;}
.HR {background-position: -180px -48px;}
.CU {background-position:   0px -60px;}
.CY {background-position: -20px -60px;}
.CZ {background-position: -40px -60px;}
.DK {background-position: -60px -60px;}
.DJ {background-position: -80px -60px;}
.DM {background-position: -100px -60px;}
.DO {background-position: -120px -60px;}
.TL {background-position: -140px -60px;}
.EC {background-position: -160px -60px;}
.EG {background-position: -180px -60px;}
.SV {background-position:   0px -72px;}
.GQ {background-position: -20px -72px;}
.ER {background-position: -40px -72px;}
.EE {background-position: -60px -72px;}
.ET {background-position: -80px -72px;}
.FK {background-position: -100px -72px;}
.FO {background-position: -120px -72px;}
.FJ {background-position: -140px -72px;}
.FI {background-position: -160px -72px;}
.FR {background-position: -180px -72px;}
.PF {background-position:   0px -84px;}
.GA {background-position: -20px -84px;}
.GM {background-position: -40px -84px;}
.GE {background-position: -60px -84px;}
.DE {background-position: -80px -84px;}
.GH {background-position: -100px -84px;}
.GI {background-position: -120px -84px;}
.GR {background-position: -140px -84px;}
.GL {background-position: -160px -84px;}
.GD {background-position: -180px -84px;}
.GU {background-position:   0px -96px;}
.GT {background-position: -20px -96px;}
.GN {background-position: -40px -96px;}
.GW {background-position: -60px -96px;}
.GY {background-position: -80px -96px;}
.HT {background-position: -100px -96px;}
.HN {background-position: -120px -96px;}
.HK {background-position: -140px -96px;}
.HU {background-position: -160px -96px;}
.IS {background-position: -180px -96px;}
.IN {background-position:   0px -108px;}
.ID {background-position: -20px -108px;}
.IR {background-position: -40px -108px;}
.IQ {background-position: -60px -108px;}
.IE {background-position: -80px -108px;}
.IL {background-position: -100px -108px;}
.IT {background-position: -120px -108px;}
.JM {background-position: -140px -108px;}
.JP {background-position: -160px -108px;}
.JO {background-position: -180px -108px;}
.KZ {background-position:   0px -120px;}
.KE {background-position: -20px -120px;}
.KI {background-position: -40px -120px;}
.KP {background-position: -80px -120px;}
.KR {background-position: -100px -120px;}
.KW {background-position: -120px -120px;}
.KG {background-position: -140px -120px;}
.LA {background-position: -160px -120px;}
.LV {background-position: -180px -120px;}
.LB {background-position:   0px -132px;}
.LS {background-position: -20px -132px;}
.LR {background-position: -40px -132px;}
.LY {background-position: -60px -132px;}
.LI {background-position: -80px -132px;}
.LT {background-position: -100px -132px;}
.LU {background-position: -120px -132px;}
.MO {background-position: -140px -132px;}
.MK {background-position: -160px -132px;}
.MG {background-position: -180px -132px;}
.MW {background-position:   0px -144px;}
.MY {background-position: -20px -144px;}
.MV {background-position: -40px -144px;}
.ML {background-position: -60px -144px;}
.MT {background-position: -80px -144px;}
.MH {background-position: -100px -144px;}
.MQ {background-position: -120px -144px;}
.MR {background-position: -140px -144px;}
.MU {background-position: -160px -144px;}
.MX {background-position: -180px -144px;}
.FM {background-position:   0px -156px;}
.MD {background-position: -20px -156px;}
.MC {background-position: -40px -156px;}
.MN {background-position: -60px -156px;}
.ME {background-position: -80px -156px;}
.MS {background-position: -100px -156px;}
.MA {background-position: -120px -156px;}
.MZ {background-position: -140px -156px;}
.MM {background-position: -160px -156px;}
.NA {background-position: -180px -156px;}
.NR {background-position:   0px -168px;}
.NP {background-position: -20px -168px;}
.NL {background-position: -40px -168px;}
.NZ {background-position: -60px -168px;}
.NI {background-position: -80px -168px;}
.NE {background-position: -100px -168px;}
.NG {background-position: -120px -168px;}
.NU {background-position: -140px -168px;}
.MP {background-position: -180px -168px;}
.NO {background-position:   0px -180px;}
.OM {background-position: -20px -180px;}
.PK {background-position: -40px -180px;}
.PW {background-position: -60px -180px;}
.PS {background-position: -80px -180px;}
.PA {background-position: -100px -180px;}
.PG {background-position: -120px -180px;}
.PY {background-position: -140px -180px;}
.PE {background-position: -160px -180px;}
.PH {background-position: -180px -180px;}
.PN {background-position:   0px -192px;}
.PL {background-position: -20px -192px;}
.PT {background-position: -40px -192px;}
.PR {background-position: -60px -192px;}
.QA {background-position: -80px -192px;}
.RO {background-position: -100px -192px;}
.RU {background-position: -120px -192px;}
.RW {background-position: -140px -192px;}
.SH {background-position: -160px -192px;}
.TD {background-position: -160px -192px;}
.KN {background-position: -180px -192px;}
.LC {background-position:   0px -204px;}
.PM {background-position: -20px -204px;}
.VC {background-position: -40px -204px;}
.WS {background-position: -60px -204px;}
.SM {background-position: -80px -204px;}
.ST {background-position: -100px -204px;}
.SA {background-position: -120px -204px;}
.SN {background-position: -160px -204px;}
.RS {background-position: -180px -204px;}
.SC {background-position:   0px -216px;}
.SL {background-position: -20px -216px;}
.SG {background-position: -40px -216px;}
.SK {background-position: -60px -216px;}
.SI {background-position: -80px -216px;}
.SB {background-position: -100px -216px;}
.SO {background-position: -120px -216px;}
.ZA {background-position: -140px -216px;}
.SS {background-position: -160px -216px;}
.ES {background-position: -180px -216px;}
.LK {background-position:   0px -228px;}
.SD {background-position: -20px -228px;}
.SR {background-position: -40px -228px;}
.SZ {background-position: -60px -228px;}
.SE {background-position: -80px -228px;}
.CH {background-position: -100px -228px;}
.SY {background-position: -120px -228px;}
.TW {background-position: -140px -228px;}
.TJ {background-position: -160px -228px;}
.TZ {background-position: -180px -228px;}
.TH {background-position:   0px -240px;}
.TL {background-position: -40px -240px;}
.TG {background-position: -60px -240px;}
.TK {background-position: -80px -240px;}
.TO {background-position: -100px -240px;}
.TT {background-position: -120px -240px;}
.TN {background-position: -140px -240px;}
.TR {background-position: -160px -240px;}
.TM {background-position: -180px -240px;}
.TC {background-position:   0px -252px;}
.TV {background-position: -20px -252px;}
.UG {background-position: -40px -252px;}
.UA {background-position: -60px -252px;}
.AE {background-position: -80px -252px;}
.GB {background-position: -100px -252px;}
.US {background-position: -120px -252px;}
.UM {background-position: -120px -252px;}
.UY {background-position: -140px -252px;}
.UZ {background-position: -160px -252px;}
.VU {background-position: -180px -252px;}
.VA {background-position:  0px -264px;}
.VE {background-position: -20px -264px;}
.VN {background-position: -40px -264px;}
.VI {background-position: -60px -264px;}
.WF {background-position: -80px -264px;}
.EH {background-position: -100px -264px;}
.YE {background-position: -120px -264px;}
.ZM {background-position: -140px -264px;}
.ZW {background-position: -160px -264px;}
.IO {background-position: -180px -264px;}
.CX {background-position:   0px -276px;}
.CC {background-position: -20px -276px;}
.GG {background-position: -40px -276px;}
.IM {background-position: -60px -276px;}
.JE {background-position: -80px -276px;}
.NF {background-position: -100px -276px;}
.BL {background-position: -120px -276px;}
.GS {background-position: -140px -276px;}