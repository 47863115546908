.settings-education_wrapper {
    width: 60%;
    margin-left: 9%;
}

.system-language {
    margin-left: -15px;
    margin-right: 15px;
}

.is-ntlm_wrapper {
    display: flex;
}

.is-ntlm_checkbox {
    margin-right: .5rem;
}

.react-edu-label {
    margin-left: 0;
    margin-right: 45px;
}
