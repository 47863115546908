.action-wrapper {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.wrapper {
    margin: 0 15px;
}

.not-active {
    opacity: .5;
}
