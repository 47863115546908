.spred {
    font-size: 21px;
    line-height: 14px;
    font-weight: 800;
    position: absolute;
    bottom: 15px;
    right: 5px;
}

.lock {
    width: 60px;
    text-align: left !important;
}

.ul-drop-menu {
    min-width: 86px !important;
}

.iso-text {
    line-height: 2rem;
}
