.react-courses {
    position: relative;
    padding: 3.1rem 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);

    &_progress-bar {
        position: absolute;
        top: -2px;
        left: -2px;
    }

    &_tooltip {
        position: absolute;
        display: none;
        width: 304px;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14),
            0px 1px 10px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        left: 19%;
        transform: translate(-50%, -120%);
        border-radius: 4px;
        padding: 3px 8px;
        background-color: #fff;
        color: #333;
        z-index: 100;
    }

    &_top {
        &-header {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: rgba(0, 0, 0, 0.87);
            margin-bottom: 32px;
        }

        &-checkbox {
            margin-bottom: 24px;
        }
    }

    &_toolbar {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        &-left,
        &-right {
            display: flex;
        }

        &-btns {
            padding: 6px 12px;
            background-color: rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            border: none;
            outline: none;
            color: rgba(0, 0, 0, 0.38);
            margin-right: 8px;
            display: flex;
            align-items: center;
            cursor: default;
            transition: background-color 0.2s ease-in-out;

            &-check {
                display: inline-flex;
                width: 14px;
                height: 10px;
                stroke: #aeaeae;
                fill: none;
                margin-right: 6px;
            }

            &-not-check {
                display: inline-flex;
                width: 12px;
                height: 12px;
                stroke: #aeaeae;
                fill: none;
                margin-right: 6px;
            }

            &-report {
                display: inline-flex;
                width: 14px;
                height: 14px;
                stroke: #fff;
                fill: none;
                margin-right: 6px;
            }

            &-delete {
                display: inline-flex;
                width: 14px;
                height: 14px;
                stroke: #aeaeae;
                fill: none;
                margin-right: 6px;
            }

            &-edit {
                display: inline-flex;
                width: 15px;
                height: 15px;
                stroke: #aeaeae;
                fill: none;
                margin-right: 6px;
            }

            &-active {
                color: #333;
                cursor: pointer;

                & svg {
                    stroke: #333;
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0.12);
                }
            }
        }

        &-report {
            display: flex;
            align-items: center;
            padding: 6px 12px;
            background-color: #4caf50;
            border-radius: 4px;
            color: #fff;
            border: none;
            outline: none;
            margin-right: 8px;
        }

        &-create {
            display: flex;
            align-items: center;
            padding: 6px 12px;
            border: none;
            outline: none;
            background-color: #37397f;
            border-radius: 4px;
            color: #fff;

            img {
                width: 9px;
                height: 9px;
                margin-right: 7px;
            }
        }
    }

    &_loader {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;

        img {
            width: 20px;
            height: 20px;
        }
    }

    &_table {
        width: 100%;
        margin-bottom: 20px;
    }

    &-title {
        padding: 8px 0;
        padding-right: 16px;
        word-break: break-all;
        color: #1976d2;
        cursor: pointer;
        max-width: 400px;

        &_sort {
            display: flex;
            align-items: center;
        }

        &_svg {
            display: inline-flex;
            width: 15px;
            height: 15px;
            margin-left: 2px;
        }

        &_svg-rotate {
            display: inline-flex;
            width: 15px;
            height: 15px;
            transform: rotate(180deg);
        }
    }

    &-list {
        &-check {
            display: inline-flex;
            width: 16px;
            height: 12px;
            fill: #aeaeae;
            stroke: none;
            cursor: pointer;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;

            &:hover {
                opacity: 1;
            }

            &.courses_check-display-start {
                opacity: 1;
                fill: #4caf50;
            }
        }

        &_row {
            transition: background-color 0.2s ease-in-out;
            border-top: 1px solid rgba(0, 0, 0, 0.12);

            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }
        }

        &_label {
            max-width: 400px;
            padding: 8px 0;
            vertical-align: top;
            word-break: break-word;
            padding-right: 16px;
            cursor: pointer;

            &-check {
                padding: 8px 0;
                vertical-align: top;
                padding-right: 14px;
            }

            &-checkbox {
                padding: 8px 0;
                vertical-align: top;
                padding-right: 5px;
            }

            &-status {
                display: flex;
                flex-direction: column;
            }

            &-task {
                vertical-align: text-top;
                padding: 3px 8px 3px;
                background-color: #fecdd2;
                border-radius: 3px;
                font-size: 12px;
                line-height: 16px;
                color: #333;
                margin: 2px 0;
                width: fit-content;
            }

            &-learn {
                padding: 3px 8px 3px;
                background-color: #ffe0b2;
                margin: 2px 0;
                border-radius: 3px;
                font-size: 12px;
                line-height: 16px;
                color: #333;
                width: fit-content;
            }
        }
    }

    &_delete-popup {
        background-color: #1c1c1c;
        box-shadow: 0px 4px 12px rgba(16, 16, 16, 0.08);
        width: max-content;
        max-width: 720px;
        padding: 16px;
        border-radius: 8px;

        &_content {
            color: #fff;
            display: flex;
            align-items: center;
            width: 100%;
        }

        &_delete {
            display: inline-flex;
            width: 40px;
            height: 40px;
            margin-right: 12px;
        }

        &_load {
            display: inline-flex;
            width: 40px;
            height: 40px;
            position: absolute;
            margin-right: 12px;
        }

        &_btn {
            padding: 6px 12px;
            background-color: rgba(255, 255, 255, 0.15);
            border-radius: 4px;
            border: none;
            outline: none;
            margin-left: 12px;
        }

        &_text {
            word-break: break-all;
            flex-basis: 90%;
        }
    }

    &_block-delete, &_block-snackbar {
        display: flex;
        justify-content: space-around;
        position: fixed;
        z-index: 100;
        padding: 16px;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: auto;
    }

    &_snackbar {
        background-color: #1c1c1c;
        box-shadow: 0px 4px 12px rgba(16, 16, 16, 0.08);
        font-weight: 600;
        color: #fff;
        display: flex;
        align-items: center;
        width: max-content;
        height: 48px;
        border-radius: 9px;

        &-arrow {
            display: inline-flex;
            width: 9px;
            height: 6px;
            margin-left: 6px;
        }

        &-count {
            margin: 0 2px;
        }

        &-left {
            border-right: 2px solid #fff;
            padding: 15px 16px;
        }

        &-right {
            padding: 8px 16px;
        }

        &-dropdown {
            position: absolute;
            background-color: #1c1c1c;
            border-radius: 4px;
            bottom: 131%;
            left: 0;
            width: max-content;
            max-width: 210px;
            text-align: left;
            word-break: break-all;

            &-item {
                padding: 6px 12px;
                cursor: pointer;
                background-color: #1c1c1c;
                color: #fff;
                border-radius: 4px;
                transition: background-color 0.2s ease-in-out;

                &:hover {
                    background-color: rgba(255, 255, 255, 0.15);
                }
            }
        }

        &-btn {
            padding: 6px 12px;
            background-color: rgba(255, 255, 255, 0.22);
            border-radius: 4px;
            border: none;
            outline: none;
            margin-right: 8px;

            &-disable {
                opacity: 0.5;
            }
        }

        &-btn-role {
            display: inline-flex;
            align-items: center;
            position: relative;
            padding: 6px 12px;
            background-color: rgba(255, 255, 255, 0.22);
            border-radius: 4px;
            border: none;
            outline: none;
            margin-right: 8px;

            &-disable {
                opacity: 0.5;
            }
        }

        &-close {
            display: inline-flex;
            width: 14px;
            height: 14px;
            stroke: #d3d3d3;
            fill: none;
            margin-left: 8px;
            cursor: pointer;
        }

        &-text {
            margin-right: 8px;
        }
    }
}

.courses-row-display-start {
    background-color: rgba(46, 125, 50, 0.04);
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: #eef0ed;
    }
}

.courses-row-active {
    background-color: #eef0ed;
}

.courses-tooltip-hover:hover~.react-courses_tooltip {
    display: block;
}

.react-checkbox-courses-list {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    width: 882px;
    height: 29px;
}

.react-checkbox-courses-list:checked {
    &+.react-checkbox-courses_label-list::before {
        content: "";
        background-image: url("../../../images/lg-select.svg");
        background-repeat: no-repeat;
        background-position: center;
    }
}

.react-checkbox-courses_label-list {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.react-checkbox-courses_label-list::before {
    content: "";
    border: 1px solid #cbcccb;
    border-radius: 4px;
    height: 20px;
    margin-right: 8px;
    padding-right: 18px;
}

.react-checkbox-courses {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    width: 290px;
    height: 22px;
}

.react-checkbox-courses:checked {
    &+.react-courses_label-checkbox::before {
        content: "";
        background-image: url("../../../images/lg-select.svg");
        background-repeat: no-repeat;
        background-position: center;
    }
}

.react-courses_label-checkbox {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.react-courses_label-checkbox::before {
    content: "";
    border: 1px solid #cbcccb;
    border-radius: 4px;
    height: 20px;
    margin-right: 8px;
    padding-right: 18px;
}

.react-checkbox-courses-all {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    width: 22px;
    height: 22px;
}

.react-checkbox-courses-all:checked {
    &+.react-checkbox-courses_label-all::before {
        content: "";
        background-image: url("../../../images/lg-select.svg");
        background-repeat: no-repeat;
        background-position: center;
    }
}

.react-checkbox-courses_label-all {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.react-checkbox-courses_label-all::before {
    content: "";
    border: 1px solid #cbcccb;
    border-radius: 4px;
    height: 20px;
    margin-right: 8px;
    padding-right: 18px;
}

.btn-report_disable {
    opacity: 0.5;
    cursor: not-allowed !important;
}

.scnackbar-transition-enter {
    opacity: 0;
    transform: scale(0.9);
}

.scnackbar-transition-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.scnackbar-transition-exit {
    opacity: 1;
}

.scnackbar-transition-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}
