.device-delete-modal {
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;

    img {
        width: 60px;
        height: 80px;
        margin-right: 20px;
    }

    &_content {
        display: flex;
        flex-direction: column;
        width: 80%;
    }

    &_top {
        margin-bottom: 15px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #555;
    }

    &_bottom {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #555;
    }
}
