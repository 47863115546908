.wifi-popup {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    padding-top: 16px;
    background-color: #1c1c1c;
    border-radius: 8px;
    width: 720px;
    height: fit-content;
    max-height: 440px;

    &_single {
        display: flex;
        align-items: center;
        padding-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
        justify-content: space-between;
        &-content {
            display: flex;
            align-items: center;
            margin-right: 10px;
        }

        &-top {
            display: flex;
            flex-direction: column;
            margin-right: 30px;
        }
    }

    &_content {
        display: flex;
        flex-direction: column;
    }

    &_list {
        padding: 0 68px;
        max-height: 340px;
        overflow-y: auto;
        scrollbar-color: rgba(255, 255, 255, 0.12) #1c1c1c;
        scrollbar-width: thin;
    }

    &_list::-webkit-scrollbar {
        width: 6px;
        height: 8px;
        background-color: #1c1c1c;
    }

    &_list::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.12);
        border-radius: 4px;
    }

    &_block {
        display: flex;
        flex-direction: column;
        margin-right: auto;
    }

    &_item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    &-item:last-child {
        margin-bottom: 24px;
    }


    &_header {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
        margin-bottom: 6px;
    }

    &_btn {
        padding: 6px 12px;
        background-color: rgba(255, 255, 255, 0.15);
        border-radius: 4px;
        color: #fff;
        border: none;
        outline: none;
    }

    &_wpapper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &_content {
        margin-right: 12px;
        width: 533px;
        display: flex;
        flex-direction: column;
    }

    &_img {
        display: inline;
        width: 40px;
        height: 40px;
        margin-right: 12px;
    }

    &_close {
        display: inline;
        width: 14px;
        height: 14px;
        cursor: pointer;
    }

    &_title {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        margin-right: auto;
    }

    &_text {
        font-size: 14px;
        line-height: 18px;
        color: #ffffff;
    }

    &_btn-ok {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        padding: 5px 12px 7px;
        background-color: rgba(255, 255, 255, 0.15);
        border: none;
        border-radius: 4px;
        transition: background-color 0.2s ease-in-out;
        margin-right: 12px;

        &:hover {
            background-color: rgba(255, 255, 255, 0.251);
        }
    }

    &_btn-close {
        padding: 10px;
        border-radius: 4px;
        transition: background-color 0.2s ease-in-out;

        img {
            width: 12px;
            height: 12px;
        }
    }
}