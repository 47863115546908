.react-attachment {

    &_mb {
        margin-bottom: 30px;
    }

    &_btn-clear {
        margin-left: auto;
        height: 22.5px;
        margin-right: 12px;
    }

    &_dropdown_open {
        display: flex;
        width: 100%;
    }

    &_fake-block {
        display: flex;
        position: relative;
        margin-bottom: 8px;

        &_label {
            position: absolute;
            left: -38%;
            width: 170px;
        }

        &_input {
            border: 1px solid #CCCCCC;
            border-radius: 4px;
            width: 100%;
            height: 40px;
            cursor: pointer;
        }
    }

    &_field {
        display: flex;
        align-items: center;
    }

    &_warning {
        font-size: 14px;
    }

    &_input {
        width: 100%;
        border: none;
        outline: none;
        height: auto !important;
    }

    &_span-fake {
        position: absolute;
        top: -1000000000px;
        font-size: 0.92rem;
        box-sizing: content-box;
    }

    &_input-edit {
        text-overflow: ellipsis;
        box-sizing: content-box;
        background-color: rgb(250 230 230);
    }

    &_text {
        width: 100%;
        align-self: center;
    }

    &_type {
        cursor: default;
        color: #aeaeae;
    }

    &_error-message {
        color: #ef5350;
    }
}

.edit-name-error {
    background-color: rgba(211, 47, 47, 0.12);
    border: 1px solid #ef5350;
    border-radius: 4px;
}

.react-checkbox_disabled {
    pointer-events: none;
    opacity: 0.5;
    background: rgb(228, 227, 227);
    border-color: #aeaeae;
}

.react-final_disabled {
    pointer-events: none;
    opacity: 0.5;
}

.react-options-selected {
    background-color: rgba(25, 118, 210, 0.12);
    align-items: center;
    justify-content: space-between;

    &_icon {
        width: 18px;
        height: 14px;
        fill: none;
    }
}
