.react-icon {
    width: 20px;
    height: 13px;
    fill: @main-color;
    &:hover {
        fill: @assets-color;
    }
    &_logout {
        fill: black;
        width: 17px;
        height: 20px;
        transform: rotate(180deg);
        cursor: pointer;
    }

    &_settings {
        fill: black;
        width: 20px;
        height: 20px;
    }

    &_scheduler {
        fill: black;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    &_direction {
        fill: black;
        fill-opacity: .54;
        margin-right: 3px;

        &__disabled {
            cursor: default;
            fill: #555;

            &:hover {
                fill: #555;
            }
        }
    }

    &_copy {
        fill: #4b8fdf;
        width: 20px;
        display: inline-block;
        height: 15px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        &:hover {
            fill: #1e5ca6;
        }

        &__disabled {
            fill: #555;

            &:hover {
                fill: #555;
            }
        }
    }

    &_default {
        fill: none!important;
        stroke: #9999A1;

        &:hover {
            stroke: #565554;
        }
    }

    &_world {
        width: 20px!important;
        height: 20px!important;
    }

    &_docx,
    &_xlsx,
    &_pptx,
    &_pdf,
    &_js,
    &_rtf,
    &_html,
    &_ics,
    &_csv,
    &_lnk {
        width: 32px;
        height: 32px;
    }
}

.list-icons {
    height: 18px;
    fill: none;
    stroke: #9999A1;
    &:hover {
        stroke: #565554;
        fill: none;
    }
}

.attachment_icon-wrapper {
    padding: 8px 12px;
    border: 1px solid #8C8C8C;
    border-right: none;
    cursor: pointer;

    &:hover {
        background-color: #D4D4D4;
    }

    &:first-child {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
    }

    &:last-child {
        border-right: 1px solid #8C8C8C;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
    }

    &__active {
        background-color: #D4D4D4;
        cursor: default;
    }
}
