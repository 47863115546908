.static-desc {
    margin-top: 10px;
    width: 200px;
    line-height: 18px;
}

.last-link {
    display: block;
    margin-top: 7.5px;
    opacity: 0;
}

dasboard-item:hover .last-link {
    opacity: 1;
}