.wrapper {
    width: 700px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Golos Text';
}

.header {
    background: rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
}

.block {
    display: flex;
    padding: 0 24px 0 24px;
}

.button-wrapper {
    display: flex;
    flex-wrap: nowrap;
}

.title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.87);
}

.body {
    display: grid;
    flex-grow: 1;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(4, 35px);
    gap: 6px;
}

.grow {
    flex-grow: 1;
}

.year {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
}

.cell {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    &:hover {
        .active-cell();
    }
}

.active-cell {
    background-color: rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.arrow {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    align-self: center;
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, 0.12);
    }

    &__disabled {
        &:hover {
            background-color: transparent;
            cursor: default;
        }
    }
}

.reverse {
    transform: rotate(180deg);
}

.datepicker-wrapper {
    padding: 9px 9px 0 9px;
}

.disabled {
    color: grey;
    cursor: default;
    &:hover {
        background-color: transparent;
    }
}

.footer {
    display: flex;
    justify-content: space-between;
    padding: 24px;
}

.time-wrapper {
    display: flex;
}

.time {
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    width: 230px;
    padding: 0 12px;
    margin-right: .9rem;

    &-range {
        font-size: 16px;
        font-weight: 500;
        margin-right: .8rem;
    }
}

.checkbox {
    width: 17px;
    height: 17px;
    border: 2px solid rgba(0, 0, 0, 0.54);
    border-radius: 4px;
    margin-right: .5rem;

    &-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &-active {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-color: #6200EA;
        background-color: #6200EA;

        &::after {
            display: block;
            content: ' ';
            width: 9px;
            height: 5px;
            transform: rotate(-45deg);
            border-left: 2px solid white;
            border-bottom: 2px solid white;
        }
    }
}
