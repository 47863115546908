
.ldap-targets-table {
    margin-bottom: 10px !important;
    font-size: 14px;
    position: relative;
    background-color: white;
    margin-left: 0;
}

.ldap-targets-table td,
.ldap-targets-table th {
    line-height: 2rem;
}

.ldap-targets-item {
    width: 30px;
    padding-left: 15px;
}

.ldap-targets-row {
    padding: 0 !important;
}

.ldap-targets-row td, .ldap-targets-row th {
    padding-top: 5px !important;
    padding-bottom: 1px !important;
    position: relative;
    padding-right: 5px;
    line-height: 20px;
    letter-spacing: 0px;
    min-height: 30px;
    height: 30px;
    vertical-align: top;
}

.ldap-targets-row th {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.checkbox {
    margin-right: 7px;
}

.ldap-targets-row:hover > td > .checkbox {
    opacity: 1 !important;
}

.ldap-targets-row-target:hover td {
    background-color: #fcf7e3;
}

.ldap-targets-table thead th a {
    color: #000;
    text-decoration: none;
    border: none;
    box-shadow: none;
    font-weight: 700;
    font-size: 14px;
}

.ldap-sort {
    margin-right: 15px;
}

.ldap-alert {
    width: max-content;
    margin-top: 20px;
    margin-left: 60px;
    font-size: 14px;
    max-width: 725px;
    text-align: left;
}

.ldap-department-alert {
    width: max-content;
    margin-top: 20px;
    margin-left: 30px;
    font-size: 14px;
}
