.nav-bar {
    position: fixed;
    top: -200px;

    box-sizing: border-box;
    display: block;
    max-width: 1020px;
    width: 100%;
    margin: 0 auto;
    padding: 1.24rem 10px .372rem;
    z-index: 1000;

    background-color: rgba(255, 255, 255, .96);

    width: 100vw;
    max-width: 100vw;
    left: 0px;
    padding-left: calc(50vw - 510px);
    padding-right: calc(50vw - 510px);
}

.nav-bar-item {
    box-sizing: border-box;
    font-size: 1.5rem;
    line-height: 1.24rem;
    cursor: pointer;
}

.nav-bar-item.active {
    cursor: default;
}

.wrapper {
    display: flex;
    justify-content: space-between;
}

.inner-text {
    display: flex;
}

.title {
    margin-right: 5px;
}

.nav-bar::before {
    content: '';
    position: absolute;
    top: 0;
    left: -50px;
    display: block;
    width: 50px;
    height: 100%;
    background-color: rgba(255, 255, 255, .96);
}

.nav-bar::after {
    content: '';
    position: absolute;
    top: 0;
    right: -50px;
    display: block;
    width: 50px;
    height: 100%;
    background-color: rgba(255, 255, 255, .96);
}

.menu-ico {
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 10px;
    z-index: 100;
    left: -30px;
    bottom: 15px;
}

.line {
    width: 100%;
    height: 2px;
    background-color: black;
    margin-bottom: 3px;
    transition-duration: .5s;
    transform-origin: 3px 1px 0;
}

.active .line1 {
    transform: rotate(45deg);
}

.active .line2 {
    width: 0;
}

.active .line3 {
    transform: rotate(-45deg);
}

.style1-nav {
    top: -156px;
}

.button-wrapper {
    position: relative;
}

.none-float {
    float: none;
}

.percent {
	font-size: 23px;
}
