.wrapper {
	width: 100%;
	padding-left: 50px;
	padding-right: 50px;
}

.square {
	display: flex;
	min-width: 155px;
	height: 110px;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
}

.icons {
	display: flex;
	justify-content: space-between;
	padding: 20px 0 0;
	margin-bottom: 1rem;
}

.square-danger {
	background-color: #DA5855;
}

.square-warning {
	background-color: #F5A829;
}

.square-succsess {
	background-color:#5EB75D;
}

.square-rotation-b {
	background-color: #A8D8A8;
	opacity: .7;
}

.square-no-active {
	background-color: gray;
}

.text-white {
	font-size: 52px;
	color: white;
}

.item {
	position: relative;
	display: flex;
	flex-direction: column;
	margin-bottom: 22px;
}

.question {
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
}

.question input {
	display: none;
}

.title {
	max-width: 75%;
	color: #4A4A4A;
	margin-bottom: 20px;
}

.question-title {
	margin-bottom: 15px;
	font-weight: 600;
}

.last-question {
	margin-bottom: 60px;
}

.panel-bottom {
	bottom: -65px;
}

.text-bold {
	font-weight: bold;
	margin-right: 16px;
}

.panel-text {
	margin-right: 10px;
	margin-left: 20px;
}

.header {
	padding-left: 0;
}

.lower {
	text-transform: lowercase;
}

.margin {
	margin-bottom: 100px;
}

.mr-15 {
	margin-right: 15px;
}

.motivation-level {
    font-weight: 600;
    color: #959595;
}
