.header {
    font-weight: 600;
    color: #333;
    padding-bottom: 19px;
    padding-bottom: 1.24rem;
}

.label {
    min-width: 107px;
    margin-right: 15px;
    text-align: right;
}

.input {
    display: block;
    box-sizing: border-box;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.item-wrapper {
    margin-bottom: 1.86rem;
}

.submit {
    display: flex;
    align-items: center;
    padding-left: 127px;
    margin-bottom: 1.24rem;
}

.btn-submit {
    margin-right: 10px;
}

.error-wrapper {
    padding-left: 127px;
}

.password-wrapper {
    padding-left: 53px;
}

.password-repeate {
    padding-left: 48px;
}

.new-pass-label {
    width: 120px !important;
    text-align: right;
}
