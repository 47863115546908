.wrapper {
    position: absolute;
    left: 0;
    width: 100%;
    width: 100vw;
    height: 0;
    z-index: 50;
    background-color: transparent;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 0.5) 100%);
    transition-duration: .5s;
    transition-property: height;
    overflow: hidden;
}

.inner-wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1020px;
    margin: 0 auto;
    padding-top: 5px;
    font-size: 14px;
}

.active.wrapper {
    height: auto;
    padding-bottom: 100px;
    overflow: hidden;
}

.menu-item {
    width: 25%;
    padding: 0 45px;
    margin-bottom: 40px;
    font-size: inherit;
}

.menu-item:nth-child(4n+3),
.menu-item:nth-child(4n) {
    padding: 0 60px;
}

.menu-item:nth-child(n+5) {
    margin-bottom: 0;
}

.menu-search {
    margin-top: -40px;
    width: 100%;
}

.search-label {
    position: relative;
    font-size: 22px;
    line-height: 50px;
    color: #000;
    padding-left: 60px;
    margin-left: 50%;
    width: 30.5%;
}

.item-header {
    display: inline-block;
    white-space: nowrap;
    color: black;
    font-size: 22px;
}

.item-title {
    position: relative;
    display: inline-block;
    padding-top: 17px;
    font-size: inherit;
    line-height: 19px;
}

.item-default {
    display: block;
    width: 100%;
    height: 26px;
}

.item-link {
    position: relative;
    display: inline-block;
    font-size: inherit;
    line-height: 1.36em;
    margin-bottom: .3rem;
}

.first-section-title {
    margin-top: 39px;
}

.second-section-title {
    margin-top: 22px;
}

.item-padding {
    margin-top: 26px;
}

.ico {
    position: absolute;
    left: -25px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
}

.ico-history {
    background-image: url('../../../images/history.svg');
}

.ico-attack {
    width: 25px;
    height: 25px;
    left: -18px;
    background-image: url(../../../images/attack.svg);
}

.ico-learning {
    background-image: url('../../../images/book.svg');
}

.ico-report {
    left: -21px;
    background-image: url('../../../images/report.svg');
}

.ico-scheduler {
    background-image: url('../../../images/scheduler.svg');
}

.noview {
    opacity: 0;
}

.close-search {
    display: inline-block;
    position: absolute;
    top: -6px;
    right: -30px;
    opacity: .6;
}

.white-none {
    white-space: normal;
}

