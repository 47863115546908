.progressbar-wrapper {
	width: 75%;
	padding: 20px 0;
}

.people-input {
	position: absolute;
	top: -6px;
}

.wrapper-company {
	position: relative;
}

.link {
	display: inline-block;
	width: 70px;
}

.list-li {
	overflow: visible!important;
}