.wrapper {
    display: flex;
    color: #555;
    flex-wrap: wrap;
    border-bottom: 1px solid #CCC;
    padding: 10px 15px 0;
    margin-bottom: 1rem;
}

.item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 1px;
    padding: 12px 17px;
    cursor: pointer;
}

.active {
    border: 1px solid #ccc;
    border-bottom: 0;
    border-radius: 5px 5px 0 0;
    background-color: white;
    cursor: default;
}

.checkbox-wrapper {
    width: 12px;
    height: 12px;
    margin-right: 1rem;
    background-size: 100%;
}

.label {
    display: inline-flex;
    align-items: center;
    margin-left: 1rem;
    cursor: pointer;
}
