.settings-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 1.86rem;
    white-space: nowrap;

    &_title {
        font-size: 24px;
        font-weight: 600;
        line-height: 2.48rem;
        color: #333;
        height: 37.195px;
        min-width: min-content;
    }
}
