.btn-wrapper {
    display: flex !important;
    width: 100%;
}

.line {
    line-height: 25px !important;
}

.input-text {
    border-radius: 0 4px 4px 0 !important;
}
