.right-time {
    position: absolute;
    left: 100%;
    width: auto;
    white-space: nowrap;
}

.time-wrapper {
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    width: auto;
    align-items: center;
    transform: translateY(-46%) translateX(135px);
    cursor: pointer;
}

.time-ico {
    width: 50px !important;
}

.right-path {
    position: absolute;
    left: 100%;
    display: flex;
    width: 100px;
}

.left-time {
    padding-right: 10px;
    white-space: nowrap;
}
