.error{
    &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
    }

    &-title {
        font-size: 1.5rem;
        color: black;
        margin-bottom: .5rem;
    }

    &-text {
        max-width: 650px;
        text-align: center;
    }
}
