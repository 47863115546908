.react-notification-modal {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;

    &_process {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &_label {
        width: 157px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #555555;
        margin-right: 20px;
    }

    &_input {
        width: 471px;
        height: 32px;
        border: 1px solid #cbcccb;
        border-radius: 4px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 14px;
        box-shadow: 0px -1px 1px rgb(243 242 242);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        line-height: 1.24rem;
        color: #555;
        appearance: none;
        outline: none;
        font-size: 14px;

        &:active {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
        &:focus {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
    }

    &_domain {
        width: 471px;
        display: flex;

        &-input {
            width: 220px;
            height: 32px;
            flex-grow: 1;
            border: 1px solid #cbcccb;
            border-radius: 4px;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 14px;
            box-shadow: 0px -1px 1px rgb(243 242 242);
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            line-height: 1.24rem;
            color: #555;
            appearance: none;
            outline: none;
            font-size: 14px;

            &:active {
                box-shadow: 0 0 6px rgb(167 206 238);
            }
            &:focus {
                box-shadow: 0 0 6px rgb(167 206 238);
            }
        }

        &_drop-wrapper {
            flex-grow: 1;
        }

        &-span {
            align-self: center;
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    &_editor {
        margin-bottom: 20px;
        border: 1px solid transparent;
    }

    &_block {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    &_top {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
    }

    &_hr {
        position: absolute;
        top: 105px;
        left: 0;
        width: 100%;
        border-top: 1px solid #cbcccc;
        z-index: 0;
    }

    &_img1 {
        position: absolute;
        top: 186px;
        left: 0;
        width: 12px;
        height: 709px;
    }

    &_img2 {
        position: absolute;
        top: 186px;
        right: 0;
        width: 12px;
        height: 709px;
    }
}

.react-notification-modal_checkbox-input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
}

.react-notification-modal_checkbox-input:checked {
    & + .react-notification-modal_checkbox-label::before {
        content: "";
        background-image: url("../../../images/lg-select.svg");
        background-repeat: no-repeat;
        background-position: center;
    }
}

.react-notification-modal_checkbox-label {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.react-notification-modal_checkbox-label::before {
    content: "";
    border: 1px solid #cbcccb;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    margin-right: 7px;
}

.text-error {
    color: #a94442;
}
.input-error {
    border-color: #a94442;
}

.react-notification-modal_block>.css-b62m3t-container {
    width: 471px !important;
}

.react-select {
    &__control {
        border: 1px solid #cbcccb !important;
        border-radius: 4px !important;
    }

    &__input-container {
        height: 32px !important;
        padding: 0 !important;
    }

    &__placeholder {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
        padding-left: 12px !important;
        vertical-align: baseline !important;
    }

    &__indicator-separator {
        display: none !important;
    }

    &__multi-value {
        margin-top: 8px !important;
        margin-bottom: 8px !important;
        background: #d8e9fb !important;
        border-radius: 4px !important;
    }
}

.stop_item {
    color: rgba(86, 85, 84, 0.6);
}
