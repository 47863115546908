.wrapper {
	display: flex;
}

.text {
	flex-grow: 4.8;
	width: 232px;
	padding-right: 10px;
	flex-direction: column;
}

.inner-text {
	display: inline-block;
	margin-bottom: 15px;
}

.link-wrapper {
	margin-bottom: 15px;
}

.link {
	display: inline;
}