.react-loader {
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: @popup-element;
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(16, 16, 16, 0.5);
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        z-index: 2008;
        background-color: @popup-element;
        animation: mask_left 8s steps(1, end) forwards;
    }
    &_animate {
        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            height: 100%;
            z-index: 2008;
            background-color: white;
            animation: mask_right 8s steps(1, end) forwards;
        }
    }

    &_content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 37px;
        height: 37px;
        background-color: @popup-element;
        border-radius:50%;
        overflow: hidden;
        z-index: 2010;
    }

    &_ico {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &_icon {
        width: 20px;
        height: 20px;
        fill: white;
    }

    &_indicator {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2005;
        animation: line 8s linear forwards;

        &:after {
            content: '' ;
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            background-color: white;
        }
    }
}

@keyframes line {
    form {}
    to {
        transform: rotate(360deg);
    }
}

@keyframes mask_left {
    0% {
        visibility: visible;
    }
    50%,
    100% {
        visibility: hidden;
    }
}

@keyframes mask_right {
    0% {
        visibility: hidden;
    }
    50%,
    100% {
        visibility: visible;
    }
}
