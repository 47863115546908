
/*
	Обертка для элементов в модальном окне, пока не настроены отступы
*/
div.c-modal-element-wrapper {
	padding-left: 15px;
	padding-right: 15px;
}


.fullwidth {
	width: 100% !important;
}

div.modal-inner__content > h2 {
	margin-bottom: 10px;
}

/*
  Затемнение редактора
*/
div.ta-toolbar {
  background-color: #f0f0f0;
  padding: 10px;
}

.ta-editor {
    min-height: 300px;
    height: auto;
    overflow: auto;
    font-family: inherit;
    font-size: 100%;
    margin:20px 0;
}

/*
	АЙ блять какой же ты мудак, верстальщик
*/

/*стр. 9846*/
input[type="text"].form-control {
	width: 100%;
}



/*
	Стили для списка сотрудников и отделов
*/

/*.department-title {

};*/

a.department-title {
	font-size: 21px;
	font-size: 1.4rem;
	line-height: 37px;
	line-height: 2.48rem;
}

input[type="checkbox"].department-checkbox {
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 10px;
}




/*
	Фикс хуйни на 9911 для корректного ui-select'a
*/
span.btn {
	margin-left:0px;
	padding-left: 12px;
}


/*
	Порнография от верстальщика. Пофиксил чуток, убрал из all.css
*/

.department__list tbody tr .change {
  -moz-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  width: 16px;
  height: 14px;
}
/*.department__list tbody tr:hover {
  background-color: #FCF7E3;
}*/
.department__list tbody tr:hover .change {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
}
/*
.risk-informer__targets-table tbody td,
.department__list tbody td {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
}
*/
.risk-informer__targets-table tbody td:first-child,
.department__list tbody td:first-child {
  padding: 0;
}
.risk-informer__targets-table tbody td:not(:first-child),
.department__list tbody td:not(:first-child) {
  border-top: 1px #eaeaea solid;
}
.department__list--editable tbody td:last-child {
  border-top: none;
}
.department__list tbody td:last-child {
  padding-left: 0px;
}

.risk-informer__target:hover,
.risk-informer__target.target-selected,
.department__target:hover,
.department__target.target-selected {
    background-color: #FCF7E3;
}

.department__table.long-marks .department__target.target-selected td:last-child {
  background-color: initial;
}

.panel-body .department__target.target-selected td:last-child {
  background-color: #FCF7E3;
}

.attack-trigger .play-toggle {
  cursor: pointer;
}
.attack-trigger .play-toggle:hover .attack-link {
  border-bottom: 1px solid #a2c5ee;
}
.attack-trigger .attack-ico {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  vertical-align: middle;
  height: 31px;
  width: 31px;
  background: #fff url("../images/play_icons.svg");
  margin-right: 5px;
  background-repeat: no-repeat;
  background-position: 0 0;
}
.attack-trigger .attack-link {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  vertical-align: middle;
  color: #4B8FDF;
  border-bottom: 1px solid white;
}
.attacks-trigger .stop {
  background-position: 0 -31px !important;
}


/*.department__list tbody tr.target-selected {
  background-color: #fcf7e3;
}*/




/*
  Модальные окна
*/
/*.center-modal {
    position: fixed;
    top: 20%;
    left: 18.5%;
    z-index: 1050;
    width: 80%;
    height: 80%;
    margin-left: -10%;
}
 */

.modal-content .modal-header {
  padding-left: 2em;
  padding-right: 2em;

  padding-top: 19px;
  padding-top: 1.24rem;
  padding-bottom: 19px;
  padding-bottom: 1.24rem;

  position: relative;
}

.modal-content .modal-header .modal-title {
  display: inline-block;
  font-weight: 500;
}

.modal-content .modal-body {
  padding-left: 2em;
  padding-right: 2em;
}

.modal-content .modal-footer {
  padding-top: 3.1rem;
  padding-top: 47px;
  padding-top: 20px;

  padding-bottom: 3.1rem;
  padding-bottom: 47px;
  padding-bottom: 20px;

  padding-left: 2em;
  padding-right: 2em;
}


.modal-content .modal-footer div .btn:first-child {
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 0px;
}

.modal-content .modal-footer div .btn {
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 12px;
}


/* Табы в хедере */
.modal-content .modal-header ul {
  display: inline-block;
}

.modal-content .modal-header ul li {
  font-size: 17px;
  font-size: 1.1rem;
  line-height: 37px;
  line-height: 2.48rem;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  padding-right: 20px;
}



.c-grey-note {
  color: #CCC;
  padding-top: 5px;
  font-style: italic;
}

.c-spacer {
  height: 1.5em
}
