@font-face {
	font-family: 'Golos Text';
	src: url('./GolosTextVF-Regular.woff2') format('woff2'), url('./GolosTextVF-Regular.woff') format('woff');
	font-weight: 1;
	font-style: normal;
}
@font-face {
	font-family: 'Golos Text';
	src: url('./GolosText-Regular.woff2') format('woff2'), url('./GolosText-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Golos Text';
	src: url('./GolosText-Medium.woff2') format('woff2'), url('./GolosText-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Golos Text';
	src: url('./GolosText-DemiBold.woff2') format('woff2'), url('./GolosText-DemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Golos Text';
	src: url('./GolosText-Bold.woff2') format('woff2'), url('./GolosText-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Golos Text';
	src: url('./GolosText-Black.woff2') format('woff2'), url('./GolosText-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}
