.miss {
    background-color: #a10bbf;
}

.title {
    margin-bottom: 10px;
    font-size: 21px;
    line-height: 28px;
    display: inline-flex;
}

.progress {
    height: 40px;
    border-radius: 0;
    box-shadow: none;
}

.progress-text {
    font-size: 19px;
    line-height: 40px;
}
