.exclamation {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #A94442;
    text-align: center;
    font-size: 13px;
    line-height: 1.6;
}

.danger {
    display: flex;
    gap: 8px;
    align-items: baseline;
}
