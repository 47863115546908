.wrapper {
    width: 1020px;
    padding-top: 16px;
}

.access {
    height: 200px;
}

.item {
    width: 25%;
    padding: 0 45px;
}

.item:nth-child(2) {
    margin-right: 10px;
}

.right-path {
    padding-left: 60px;
}

.wrapper-dashboard:hover .grey {
    filter: grayscale(1);
}

.wrapper-dashboard .grey:hover {
    filter: grayscale(0);
}
