.react-modal-auth {
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 15px;
    padding-bottom: 15px;

    &_block {
        display: flex;
        align-items: center;
        margin-bottom: 1.24rem;
    }

    &_process {
        display: flex;
        align-items: center;
        border-top: 1px solid #e5e5e5;
    }

    &_input {
        display: inline-block;
        width: 60px;
        height: 34px;
        padding: 6px 12px;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0px -1px 1px rgb(243 242 242);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        line-height: 1.24rem;
        color: #555;
        appearance: none;
        outline: none;
        font-size: 14px;
        &:active {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
        &:focus {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
    }
}

.text-error {
    color: #a94442;
}
.input-error {
    border-color: #a94442;
}
