.react-drag {
    position: relative;
    &_text {
        display: inline-block;
        background: #f8f8f8;
        border: 5px dashed #ddd;
        width: 100%;
        height: 200px;
        text-align: center;
        margin: 10px 0;
        font-size: 2em;
        color: #aaa;
        line-height: 4em;
    }


    &_btn {
        position: absolute;
        left: calc(50% - 70px);
        display: inline;
        bottom: 70px;
        margin: 0 auto;
        padding: 5px 20px;
        color: #fff;
        background-color: #337ab7;
        border: none;
        border-radius: 4px;
        transition: background-color .2s ease-in-out;


        &:hover {
            background-color: #286090;
        }
    }
}
