.wrapper {
    width: 100%;
    margin-bottom: 12px;
}

.label {
    width: 31%!important;
    margin-right: 17px;
    font-size: .93rem;
    text-align: right;
    line-height: 1.35rem;
}

.input-wrapper {
    display: flex!important;
    flex-wrap: wrap;
    min-height: 39px;
    width: 45% !important;
    overflow: hidden;
}

.input-wrapper .ui-select-match-item{
    background-color: #dbeafa;
    color: #4b8fdf;
    border-color: #dbeafa;
    font-size: 14px;
    padding-left: 5px;
    overflow: hidden;
}

.input {
    height: 22px!important;
    outline: none;
    border: none;
}

.item {
    display: inline-block;
    margin-right: 7px;
    padding: 3px 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.item-value {
    margin-right: 5px;
}

.form-control {
    padding: 6px 12px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}