.modal-course {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;

    &-errors {
        width: 320px;
        margin-left: 177px;
        margin-bottom: 20px;
    }

    &_size-success {
        color: #3c763d;
    }

    &_preview-delete {
        color: #a94442;
        cursor: pointer;
    }

    &_preview-block {
        display: flex;
    }

    &_preview-size {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
    }

    &_img-preview {
        margin-right: 10px;
        max-width: 301px;
        max-height: 155px;
    }

    &_upload {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }

    &_select {
        width: 301px;
    }

    &_label {
        display: flex;
        text-align: right;
        justify-content: flex-end;
        width: 126px;
        font-weight: 400;
        line-height: 20px;
        color: #555555;
        margin-right: 20px;
    }

    &_label-preview {
        display: flex;
        text-align: right;
        justify-content: flex-end;
        width: 126px;
        font-weight: 400;
        line-height: 20px;
        margin-right: 20px;
        color: #4b8fdf;
        cursor: pointer;

        &:hover {
            color: #1e5ca6;
        }
    }

    &_input {
        width: 301px;
        height: 40px;
        border: 1px solid #cbcccb;
        border-radius: 4px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 14px;
        box-shadow: 0px -1px 1px rgb(243 242 242);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        line-height: 1.24rem;
        color: #555;
        appearance: none;
        outline: none;

        &:active {
            box-shadow: 0 0 6px rgb(167 206 238);
        }

        &:focus {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
    }

    &_input-passcore {
        width: 75.6px;
        height: 40px;
        border: 1px solid #cbcccb;
        border-radius: 4px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 14px;
        box-shadow: 0px -1px 1px rgb(243 242 242);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        line-height: 1.24rem;
        color: #555;
        appearance: none;
        outline: none;
    }

    &_input-cert {
        width: 67.8px;
        height: 40px;
        border: 1px solid #cbcccb;
        border-radius: 4px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 14px;
        box-shadow: 0px -1px 1px rgb(243 242 242);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        line-height: 1.24rem;
        color: #555;
        appearance: none;
        outline: none;
        margin-right: 10px;
    }

    &_input-file {
        cursor: not-allowed !important;
        width: 266px;
        height: 40px;
        border: 1px solid #cbcccb;
        background-color: #eee;
        border-radius: 4px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 14px;
        box-shadow: 0px -1px 1px rgb(243 242 242);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        line-height: 1.24rem;
        color: #555;
        appearance: none;
        outline: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &-btn {
            height: 40px;
            padding: 6px 12px;
            text-align: center;
            background-color: #eee;
            border: 1px solid #ccc;
            border-radius: 4px;
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &-icon::before {
            content: '\f1f8';
        }
    }

    &_languages-selector {
        display: flex;
        color: #555;
        flex-wrap: wrap;
        border-bottom: 1px solid #CCC;
        padding: 10px 15px 0;
        margin-bottom: 1rem;

        &_item {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            top: 1px;
            padding: 12px 17px;
            cursor: pointer;

            &-active {
                border: 1px solid #ccc;
                border-bottom: 0;
                border-radius: 5px 5px 0 0;
                background-color: white;
                cursor: default;
            }
        }
    }

    &_block {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding-left: 2em;
        padding-right: 2em;
    }

    &_block-bottom {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding-left: 9em;
        padding-right: 2em;
    }

    &_preview {
        flex-basis: 55%;
    }

    &_drag-block {
        padding-left: 2em;
        padding-right: 2em;
    }


    &_language {
        margin-bottom: 15px;
    }


    &_process {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-top: 1px solid #e5e5e5;
        padding-right: 15px;
    }
}

.react-hidden {
    opacity: 0;
    height: 0;
    width: 0;
    line-height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
}


.react-checkbox-courses-modal {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    width: 22px;
    height: 22px;
}

.react-checkbox-courses-modal:checked {
    &+.react-checkbox-courses_label-modal::before {
        content: "";
        background-image: url("../../../images/lg-select.svg");
        background-repeat: no-repeat;
        background-position: center;
    }
}

.react-checkbox-courses_label-modal {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.react-checkbox-courses_label-modal::before {
    content: "";
    border: 1px solid #cbcccb;
    border-radius: 4px;
    height: 20px;
    margin-right: 8px;
    padding-right: 18px;
}

.modal-course_checkbox-appointment {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    width: 22px;
    height: 22px;
}

.modal-course_checkbox-appointment:checked {
    &+.modal-course_label-appointment::before {
        content: "";
        background-image: url("../../../images/lg-select.svg");
        background-repeat: no-repeat;
        background-position: center;
    }
}

.modal-course_label-appointment {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.modal-course_label-appointment::before {
    content: "";
    border: 1px solid #cbcccb;
    border-radius: 4px;
    height: 20px;
    margin-right: 19px;
    padding-right: 18px;
}