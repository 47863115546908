.section-header {
	display: flex;
	padding: 1.86rem 10px;
}

.section-header__title {
}

.section-header__title-text {
	margin-bottom: 13px;
	font-size: 1.7rem;
	color: black;
	font-weight: 600;
}

.section-header__title-postfix {
}

.section-header__right-path {
	margin-left: auto;
}