.wrapper {
    display: flex;
}

.wrapper-toolbar {
    padding-left: 30px;
    align-items: center;
}

.alert-wrapper {
    padding-top: 10px;
    padding-left: 15px;
}

.item-wrapper {
    padding-left: 30px;
}

.item-wrapper-targets {
    padding-left: 0px;
    padding-top: 5px;
}

.target-item {
    width: 30px;
}

.department-table {
    margin-bottom: 20px !important;
}

.step-3 {
    float: left;
    padding-left: 15px;
    margin-right: 8px;
}

.ldap-alert {
    width: max-content;
    margin-top: 20px;
    margin-left: 30px;
    font-size: 14px;
    max-width: calc(100% - 60px);
}

.ldap-rebuild-container {
    margin: 0 0 0 35px;
    display: block;
}

.ldap-rebuild-container > div {
    margin: 0;
    padding: 10px 20px 10px 0;
}

.table-row {
    line-height: 38px;
}

.link {
    margin-right: 10px;
}

.link-left {
    margin-right: auto;
}

.show-yet {
    color: #4b8fdf;
}

.button {
    color: black;
    background-color: #ddd;
    border-color: #ccc;
}
