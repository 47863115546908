.flex-wrapper {
    display: flex;
    align-items: center;
}

.flex-baseline {
    display: flex;
    align-items: baseline;
}

.wrapper {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.space-between {
    justify-content: space-between;
}

.flex-column {
    flex-direction: column;
}

.justify-between {
	justify-content: space-between;
}
