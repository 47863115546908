.menu-ico {
    position: absolute;
    top: 40px;
    left: 0;
    width: 30px;
    height: 20px;
    z-index: 100;
}

.line {
    width: 100%;
    height: 3px;
    background-color: black;
    margin-bottom: 5px;
    transition-duration: .5s;
    transform-origin: 4px 1px 0;
}

.active .line1 {
    transform: rotate(45deg);
}

.active .line2 {
    width: 0;
}

.active .line3 {
    transform: rotate(-45deg);
}

.classic-menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    height: 0;
    padding: 0 45px;
    transition-duration: .5s;
    background-color: #c9c9c9
}

.active.classic-menu {
    height: 1000px;
}

.no-access {
    padding-bottom: 0 !important;
}

.version-1 {
    height: 205px;
}

.version-2 {
    height: 310px;
}

.covering {
    min-height: 394px;
}

.checkbox {
    display: inline-block;
    position: absolute;
    top: 30px;
    margin: 0;
}

.loader {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%);
}
