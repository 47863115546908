.link {
    margin-top: -15px !important;
}

.loading-container {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    margin: 0 5px;
}

.loading-container svg {
    position: absolute;
    top: 5px;
}

.alert-error {
    margin-bottom: 0;
    margin-top: 10px;
}
