.react-modal-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 15px;
    padding-bottom: 15px;

    &_label {
        text-align: center;
        margin-bottom: 1.5rem;
    }

    &_wrapper {
        display: flex;
        position: relative;
    }

    &_pass {
        font-size: 0.92rem;
        line-height: 1.24rem;
        color: #555;
        appearance: none;
        outline: none;
        height: 40px;
        width: 300px;
        padding: 6px 12px;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0px -1px 1px rgb(243 242 242);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &.active {
            background-color: #fff;
        }

        &.passive {
            background-color: #eee;
        }

        &:focus {
            border-color: #66afe9;
            outline: 0;
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .08), 0 0 8px rgba(102, 175, 233, .6);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .08), 0 0 8px rgba(102, 175, 233, .6);
        }

        &:active {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
        &:focus {
            box-shadow: 0 0 6px rgb(167 206 238);
        }
    }

    &_icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        width: 25px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
    }

    &_error {
        display: flex;
        align-items: center;
        border-top: 1px solid #e5e5e5;
    }
}

.modal-icon_hidden {
    background-image: url("../../../images/icon_hide.svg");
}

.modal-icon_show {
    background-image: url("../../../images/icon_show.svg");
    opacity: 1;
}
