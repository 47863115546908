.report {
    margin-bottom: 1rem;
}

.report-wrapper {
    padding-left: 1.8rem;
    padding-top: 5px;
}

.report-item {
    display: block;
    margin-bottom: .3rem;
}
