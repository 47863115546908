.wifi-token-modal {
    padding: 30px;
    position: relative;
    display: flex;
    flex-direction: column;

    &_top {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }

    &_content {
        margin-bottom: 30px;
    }

    &_token {
        border-bottom: 1px solid #cacaca;
        padding-bottom: 15px;
        font-size: 13px;
    }

    &_bottom {
        display: flex;
        justify-content: space-between;

    }
}
