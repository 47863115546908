.wrapper {
    padding-left: 50px;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    font-size: 14px;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 5px;
    height: 38px;
    align-items: center;
    padding: 0 39px 0 10px;
    color: #000;
}

.header-text {
    color: black;
    flex-grow: 1;
    cursor: default;
}

.text-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 34px 0 10px;
}

.header-link {
    padding: 5px;
    margin-left: 20px;
    cursor: pointer;
}

.dark {
    padding: 4px 39px 4px 10px;
    color: #4A4A4A;
}

.light {
    padding: 4px 39px 4px 10px;
    color: white;
}

.graph-wrapper {
    height: 1032px;
    width: 1032px;
    display: flex;
    flex-wrap: wrap;
    color: white;
    font-size: 20px;
}

.first-group {
    width: 1032px;
    height: 516px;
}

.second-group {
    width: 516px;
    height: 516px;
}

.little-group {
    display: flex;
    flex-wrap: wrap;
    width: 516px;
    height: 516px;
}

.third-group {
    width: 516px;
    height: 258px;
}

.four-group {
    width: 258px;
    height: 258px;
}

.five-group{
    width: 258px;
    height: 258px;
}

.title {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    line-height: 24px;
}

.group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 12px 20px 20px;
    cursor: pointer;
    box-shadow: 0 0 1px 1px #ededed inset;
}

.no-interactive {
    cursor: default;
}

.group-num {
    width: 65px;
    text-align: right;
}

.people {
    width: 60px;
    margin-right: 30px;
}

.rating {
    position: relative;
    width: 71px;
}

.rating> .sub-value {
    left: 96%;
}

.row-group {
    width: 1032px;
    display: flex;
    min-height: 38px;
    font-size: 20px;
    align-items: center;
    padding: 10px 39px 6px 10px;
    cursor: pointer;
}

.row-item {
    position: relative;
    width: 65px;
    padding-right: 6px;
    text-align: right;
}

.column-item {
    position: relative;
    margin-top: 15px;
}

.negativeRatingDown {
    background-color: #CE1916;
    color: white;
    box-shadow: 0 0 1px 1px #ededed inset;
}

.negativeRatingUp {
    background-color: #D95250;
    color: white;
    box-shadow: 0 0 1px 1px #ededed inset;
}

.positiveRatingDown {
    background-color: #5DB75C;
    color: white;
    box-shadow: 0 0 1px 1px #ededed inset;
}

.positiveRatingUp {
    background-color: #1D9C1B;
    color: white;
    box-shadow: 0 0 1px 1px #ededed inset;
}

.noRatingChanges {
    background-color: #F5F5F5;
    color: rgb(85, 85, 85);
    box-shadow: 0 0 1px 1px #ededed inset;
    color: #4A4A4A;
}

.no-groups {
    margin-top: 38px;
    border: 1px solid #ededed;
}

.sub-value {
    font-size: 14px;
    position: absolute;
    top: -9px;
}

.column-item>.sub-value {
    top: -13px;
    left: 13px;
}

.link-active {
    font-weight: 700;
    color: black;
    box-shadow: none;
}

.link-active:hover {
    font-weight: 700;
    color: black;
    box-shadow: none;
}
