.entry__right {
    display: flex;
    justify-content: flex-end;
}

.entry {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1020px;
    margin: 0 auto 12px;
    box-sizing: border-box;
}

.style1_entry {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1020px;
    margin: 0 auto 12px;
    box-sizing: border-box;
    align-items: center;
}

.entry-person__name {
    margin: 0 16px;
}

.entry-link__name {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.entry-link__name:hover {
    box-shadow: none;
}


.entry-link__name:hover .ico {
    opacity: 1;
}

.ico {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 21px;
    height: 21px;
    opacity: .7;
    transition-duration: .5s;
}

.ico:hover {
    opacity: 1;
}

.setting-svg {
    top: 1px;
    margin-right: 5px;
    background: url('../../../images/settings.svg') no-repeat;
}

.scheduler-svg {
    background: url('../../../images/scheduler-large.svg') no-repeat;
    cursor: pointer;
}

.logout-svg {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
    background: url('../../../images/logout.svg') no-repeat;
    cursor: pointer;
}

.logo-mode img, .af-logo img {
    max-height: 70px;
    width: auto;
}

.af-logo {
    position: absolute;
    margin-left: -65px;
    margin-top: -24px;
}
