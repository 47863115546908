.wifi-device {
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333;
    padding-top: 3.1rem;
    margin-bottom: 3.1rem;

    &_popup-position {
        position: absolute;
        top: 105%;
        left: 30%;
    }

    &_popup-icon {
        margin-right: 12px;
        width: 40px;
        height: 40px;
    }

    &_popup-text {
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
    }

    &_dots-menu {
        border: none;
        border-radius: 8px;
        width: 32px;
        height: 32px;
        background-color: transparent;
        transition: background-color .2s ease-in-out;
    }

    &_dots-icon {
        width: 2px;
        height: 12px;
        fill: #999;
        transition: fill .2s ease-in-out;
    }

    &_btn {
        display: flex;
        border: none;
        background-color: transparent;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
        padding: 6px 12px;
        transition: background-color 0.2s ease-in-out;
        border-radius: 4px;
        &:hover {
            background-color: rgba(0, 0, 0, 0.12);
        }
    }

    &_btn-token {
        width: 16px;
        height: 8px;
        stroke: black;
        fill: transparent;
        margin-right: 6px;
    }

    &_btn-delete {
        width: 14px;
        height: 16px;
        stroke: black;
        fill: transparent;
        margin-right: 6px;
    }

    &_btn-edit {
        width: 15px;
        height: 15px;
        stroke: black;
        fill: transparent;
        margin-right: 6px;
    }

    &_button-sector {
        position: absolute;
        z-index: 1;
        top: 109%;
        left: 96%;
        display: flex;
        width: 181px;
        height: 96px;
        flex-direction: column;
        background: #ffffff;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14),
            0px 1px 10px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
    }

    &_top {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
    }

    &_table {
        width: 100%;
    }

    &_loader {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;

        img {
            width: 20px;
            height: 20px;
        }
    }

    &_empty {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #333;
        border-top: @border;
        &-content {
            padding: 8px;
        }
    }

    &_add-link {
        a {
            padding: 5px 12px 7px 12px;
            background: #f3f3f3;
            border-radius: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            transition: background-color 0.2s ease-in-out;
            &:hover {
                background-color: rgba(0, 0, 0, 0.09);
                box-shadow: none;
            }
        }
    }

    &_link {
        display: flex;
        align-self: baseline;
    }

    &_arrow {
        border-right: 1.5px solid @multiple-color;
        border-top: 1.5px solid @multiple-color;
        width: 7.5px;
        height: 7.5px;
        margin-right: 0.5rem;

        &__bottom {
            transform: rotate(135deg);
            align-self: center;
        }
        &__top {
            transform: rotate(-45deg);
            align-self: baseline;
        }
    }

    &_label {
        padding: 8px;
        vertical-align: text-top;
        word-break: break-all;
    }

    &_label-link {
        cursor: pointer;

        &:hover {
            box-shadow: none !important;
        }
    }

    &_row {
        position: relative;
        transition: background-color 0.2s ease-in-out;
        border-top: @border;
        cursor: default;
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
        &:hover .wifi-device_dots-icon {
            fill: #333;
        }
        &:hover .wifi-device_dots-menu {
            background-color: rgba(0, 0, 0, 0.12);
        }
    }

    &-title {
        font-weight: 600;
        color: #000;
        padding: 8px;
    }

    &_header {
        color: #555;
        margin-right: auto;
        font-size: 1.4rem;
        line-height: 2.48rem;
    }

    &_status {
        display: flex;
        align-items: flex-start;
        span {
            width: 90%;
        }
    }

    &_icon-btn {
        display: flex;
        justify-content: space-between;
    }

    &_icon-delete {
        width: 15px;
        height: 16.67px;
        fill: none;

        & path {
            stroke: #999;
        }
    }

    &_icon-edit {
        width: 15px;
        height: 16.67px;
        fill: none;

        & path {
            stroke: #999;
        }
    }
}

.status-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.dots-menu-active {
    background-color: rgba(0, 0, 0, 0.12);

    &>.wifi-device_dots-icon {
        fill: #000;
    }
}


