.common-link-error {
    padding-left: 45px;
}

.error-left {
    padding-left: 0;
    padding-right: 10px;
}

.error-right {
    padding-left: 10px;
}

.error-classic {
    white-space: normal !important;
    display: block;
    padding-left: 0;
}
